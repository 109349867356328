import { ProblemTkdDescriptionEnum } from 'api/cards/cards.enum';
import { ScheduleTransition, ScheduleTransitionDescriptionEnum } from 'api/schedules/schedules.enum';
import {
  QueryKeyLocalStorageEnum,
  SchedulesExceptionsDateSortEnum,
  SchedulesQueryKeysEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';

export const SchedulesExceptionsTableHeader = () => {
  const { formatMessage } = useLocale();

  const problemTkdFilterOptions = [
    {
      name: formatMessage({ id: AppMessages[ProblemTkdDescriptionEnum.true] }),
      filterBy: 'true',
    },
    {
      name: formatMessage({ id: AppMessages[ProblemTkdDescriptionEnum.false] }),
      filterBy: 'false',
    },
  ];

  const transitionFilterOptions = Object.values(ScheduleTransition).map((option) => ({
    name: formatMessage({ id: ScheduleTransitionDescriptionEnum[option] }),
    filterBy: option,
  }));

  return (
    <>
      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.SCHEDULES_EXCEPTIONS}
        sortBy={SchedulesExceptionsDateSortEnum}
        queryHeader={formatMessage({ id: AppMessages['schedules.exceptions.filters.date'] })}
      />

      <Table.HeaderFilterItem
        queryKey={QueryKeyLocalStorageEnum.SCHEDULES_EXCEPTIONS}
        queryName={SchedulesQueryKeysEnum.TYP}
        filterItems={transitionFilterOptions}
        queryHeader={formatMessage({ id: AppMessages['schedules.exceptions.filters.transition'] })}
        queryHeaderDescriptions={ScheduleTransition}
      />

      <Table.HeaderFilterItem
        queryKey={QueryKeyLocalStorageEnum.SCHEDULES_EXCEPTIONS}
        queryName={SchedulesQueryKeysEnum.PROBLEM_TKD}
        filterItems={problemTkdFilterOptions}
        queryHeader={formatMessage({ id: AppMessages['schedules.exceptions.filters.reader'] })}
        queryHeaderDescriptions={ProblemTkdDescriptionEnum}
      />
    </>
  );
};
