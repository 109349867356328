import { SchedulePeriod } from 'api/schedules/schedules.types';
import { DaysOfWeekEnum } from '../scheduleDetails/ScheduleDetails.enum';

export const transformDays = (
  dni: Record<DaysOfWeekEnum, SchedulePeriod[]>,
): Record<DaysOfWeekEnum, SchedulePeriod[]> => {
  for (const periods of Object.values(dni)) {
    for (const period of periods) {
      if (period.endTime === '23:59') {
        period.endTime = '00:00';
      }
    }
  }
  return dni;
};
