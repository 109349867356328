import { useState } from 'react';
import { Option } from 'shared-ui';

import { TableSearch } from './TableSearch';
import { TableSearchContainerProps } from './TableSearch.types';
import { useSearchByOwners } from './hooks/useSearchByOwners';
import { useSearchByPositions } from './hooks/useSearchByPositions';
import { useSearchByGroups } from './hooks/useSearchByGroups';
import { useSearchByCardsAndOwners } from './hooks/useSearchByCardsAndOwners';
import { useSearchByOwnersAndUsers } from './hooks/useSearchByOwnersAndUsers';
import { useSearchByUsers } from './hooks/useSearchByUsers';
import { useSearchByCardsAndUsers } from './hooks/useSearchByCardsAndUsers';
import { useSearchByOwnerGroups } from './hooks/useSearchByOwnerGroups';
import { useSearchByGlc } from './hooks/useSearchByGlc';
import { useSearchByTerminals } from './hooks/useSearchByTerminals';
import { useSearchByAnnouncements } from './hooks/useSearchByAnnouncements';
import { useSearchByServerAnnouncements } from './hooks/useSearchByServerAnnouncements';
import { useSearchByServerSchedules } from './hooks/useSearchByServerSchedules';
import { useSearchBySchedules } from './hooks/useSearchBySchedules';

export const TableSearchContainer = (props: TableSearchContainerProps) => {
  const [optionsQuery, setOptionsQuery] = useState('');
  const [localSelectedValue, setLocalSelectedValue] = useState<Option | null>(null);

  const ownersProps = useSearchByOwners(
    optionsQuery,
    props.searchBy === 'owners',
    false,
    false,
    props.setTableSearchQuery,
  );
  const positionsProps = useSearchByPositions(optionsQuery, props.searchBy === 'positions');
  const groupsProps = useSearchByGroups(optionsQuery, props.searchBy === 'groups');
  const cardsAndOwnersProps = useSearchByCardsAndOwners(
    optionsQuery,
    props.searchBy === 'cardsAndOwners',
    props.setTableSearchQuery,
  );
  const ownersAndUsersProps = useSearchByOwnersAndUsers(
    optionsQuery,
    props.searchBy === 'ownersAndUsers',
    'tableSearchByQuery' in props && props.searchBy === 'ownersAndUsers' ? props.tableSearchByQuery : undefined,
    'setTableSearchByQuery' in props && props.searchBy === 'ownersAndUsers' ? props.setTableSearchByQuery : undefined,
  );
  const usersProps = useSearchByUsers(optionsQuery, props.searchBy === 'users');
  const cardsAndUsersProps = useSearchByCardsAndUsers(
    optionsQuery,
    props.searchBy === 'cardsAndUsers',
    'tableSearchByQuery' in props && props.searchBy === 'cardsAndUsers' ? props.tableSearchByQuery : undefined,
    'setTableSearchByQuery' in props && props.searchBy === 'cardsAndUsers' ? props.setTableSearchByQuery : undefined,
  );
  const ownerGroupsProps = useSearchByOwnerGroups(
    optionsQuery,
    props.searchBy === 'ownerGroups',
    'ownerId' in props ? props.ownerId : '',
    props.selectedValue || localSelectedValue,
  );
  const glcProps = useSearchByGlc(
    optionsQuery,
    props.searchBy === 'glc',
    props.searchBy === 'glc' ? props?.serverId : undefined,
  );
  const terminalsProps = useSearchByTerminals(
    optionsQuery,
    props.searchBy === 'terminals',
    props.searchBy === 'terminals' ? props?.serverId : undefined,
  );
  const serverAnnouncementsProps = useSearchByServerAnnouncements(
    optionsQuery,
    props.searchBy === 'serverAnnouncements',
    props.searchBy === 'serverAnnouncements' ? props?.serverId : undefined,
  );
  const announcementsProps = useSearchByAnnouncements(optionsQuery, props.searchBy === 'announcements');
  const serverSchedulesProps = useSearchByServerSchedules(
    optionsQuery,
    props.searchBy === 'serverSchedules',
    props.searchBy === 'serverSchedules' ? props?.serverId : undefined,
  );
  const schedulesProps = useSearchBySchedules(optionsQuery, props.searchBy === 'schedules');

  const getTableSearchProps = () => {
    switch (props.searchBy) {
      case 'owners':
        return ownersProps;
      case 'positions':
        return positionsProps;
      case 'groups':
        return groupsProps;
      case 'cardsAndOwners':
        return cardsAndOwnersProps;
      case 'ownersAndUsers':
        return ownersAndUsersProps;
      case 'users':
        return usersProps;
      case 'cardsAndUsers':
        return cardsAndUsersProps;
      case 'ownerGroups':
        return ownerGroupsProps;
      case 'glc':
        return glcProps;
      case 'terminals':
        return terminalsProps;
      case 'serverAnnouncements':
        return serverAnnouncementsProps;
      case 'announcements':
        return announcementsProps;
      case 'serverSchedules':
        return serverSchedulesProps;
      case 'schedules':
        return schedulesProps;
    }
  };

  return (
    <TableSearch
      {...props}
      {...getTableSearchProps()}
      setOptionsQuery={setOptionsQuery}
      selectedValue={props.selectedValue || localSelectedValue}
      setSelectedValue={props.setSelectedValue || setLocalSelectedValue}
    />
  );
};
