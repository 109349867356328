import { useEffect } from 'react';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyLocalStorageEnum, SchedulesQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { useExpandedServerId } from 'hooks/useExpandedServerId/useExpandedServerId';
import { fetchSchedules } from 'api/schedules/schedules';

import { SchedulesTable } from './SchedulesTable';
import { SchedulesTableContainerProps } from './SchedulesTable.types';

export const SchedulesTableContainer = ({
  expandedServer,
  setSchedulesCount,
  schedulesTableSearchQuery,
}: SchedulesTableContainerProps) => {
  const serverId = expandedServer?.id || '';
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.SCHEDULES]: schedulesQuery },
  } = useLocaleStoreQuery();
  const { setExpandedServerId } = useExpandedServerId();

  const { data: schedulesResponse } = useQuery(
    [QueryKeyEnum.SCHEDULES, serverId, schedulesQuery, schedulesTableSearchQuery],
    () => fetchSchedules(serverId),
    {
      args: { ...schedulesQuery, [SchedulesQueryKeysEnum.QUERY]: schedulesTableSearchQuery },
      enabled: !!expandedServer,
      onSuccess: (response) => {
        setSchedulesCount(response.count);
      },
    },
  );

  useEffect(() => {
    setExpandedServerId(serverId);
  }, [serverId]);

  return <SchedulesTable schedulesResponse={schedulesResponse} schedulesTableSearchQuery={schedulesTableSearchQuery} />;
};
