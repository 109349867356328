import { EmptyList, useLocale } from 'shared-ui';

import { Table } from 'ui/table/Table';
import { AppMessages } from 'i18n/messages';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { QueryKeyLocalStorageEnum, SchedulesQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';

import { SchedulesTableProps } from './SchedulesTable.types';
import { SchedulesTableHeader } from './schedulesTableHeader/SchedulesTableHeader';
import { SchedulesTableRow } from './schedulesTableRow/SchedulesTableRow';

export const SchedulesTable = ({ schedulesResponse, schedulesTableSearchQuery }: SchedulesTableProps) => {
  const { formatMessage } = useLocale();
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.SCHEDULES]: schedulesQuery },
  } = useLocaleStoreQuery();

  const areSomeFiltersSelected = Object.entries(schedulesQuery).some(
    ([key, value]) =>
      ![SchedulesQueryKeysEnum.SORT, SchedulesQueryKeysEnum.LIMIT, SchedulesQueryKeysEnum.OFFSET].includes(
        key as SchedulesQueryKeysEnum,
      ) && !!value,
  );

  return (
    <Table
      gridTemplateColumns="1fr 1fr 1fr 140px"
      data={schedulesResponse?.data || []}
      nothingToShowText={
        areSomeFiltersSelected || !!schedulesTableSearchQuery ? (
          <EmptyList
            iconType="loop"
            mainText={formatMessage({ id: AppMessages['schedules.noResults.mainText'] })}
            helperText={formatMessage({ id: AppMessages['schedules.noResults.helperText'] })}
          />
        ) : (
          formatMessage({ id: AppMessages['schedules.noResults.nothingToShowText'] })
        )
      }
      renderHeader={() => <SchedulesTableHeader />}
      renderRow={(schedule) => <SchedulesTableRow schedule={schedule} />}
      customTableHeight={() => undefined}
    />
  );
};
