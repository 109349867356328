import { useLocale } from 'shared-ui';

import {
  ScheduleInputOutput,
  ScheduleTransition,
  ScheduleTypeEnum,
  ScheduleVerificationMethods,
} from 'api/schedules/schedules.enum';
import { AppMessages } from 'i18n/messages';
import { SchedulePeriodType } from 'api/schedules/schedules.types';

export type ScheduleTypeValues = {
  [K in ScheduleTypeEnum]: {
    [T in SchedulePeriodType]?: { label: string; color: string };
  };
};

export const useScheduleTypeValues = () => {
  const { formatMessage } = useLocale();

  const scheduleTypeValues: ScheduleTypeValues = {
    [ScheduleTypeEnum.METODY_WERYFIKACJI]: {
      [ScheduleVerificationMethods.KARTA]: {
        label: formatMessage({ id: AppMessages['schedules.verificationMethod.card'] }),
        color: '#3E7EBA',
      },
      [ScheduleVerificationMethods.KARTA_PIN]: {
        label: formatMessage({ id: AppMessages['schedules.verificationMethod.cardWithPin'] }),
        color: '#65B5FF',
      },
    },
    [ScheduleTypeEnum.WEJSCIE_WYJSCIE]: {
      [ScheduleInputOutput.WEJSCIE]: {
        label: formatMessage({ id: AppMessages['schedules.inputOutput.input'] }),
        color: '#9747FF',
      },
      [ScheduleInputOutput.WYJSCIE]: {
        label: formatMessage({ id: AppMessages['schedules.inputOutput.output'] }),
        color: '#D8C8FC',
      },
    },
    [ScheduleTypeEnum.PRZEJSCIE]: {
      [ScheduleTransition.ODBLOKOWANE]: {
        label: formatMessage({ id: AppMessages['schedules.transition.unlocked'] }),
        color: '#BB2D30',
      },
      [ScheduleTransition.WARUNKOWO_ODBLOKOWANE]: {
        label: formatMessage({ id: AppMessages['schedules.transition.conditionallyUnlocked'] }),
        color: '#F9E29C',
      },
      [ScheduleTransition.ZABLOKOWANE]: {
        label: formatMessage({ id: AppMessages['schedules.transition.blocked'] }),
        color: '#E27A25',
      },
    },
  };

  return { scheduleTypeValues };
};
