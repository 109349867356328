import styled from 'styled-components';

import { ReactComponent as TerminalsIconBase } from 'assets/icons/terminals-active.svg';
import { ReactComponent as TrashIconBase } from 'assets/icons/primary-trash.svg';
import { ReactComponent as ArrowRightIconBase } from 'assets/icons/arrow-right.svg';

export const TerminalsIcon = styled(TerminalsIconBase)`
  cursor: pointer;
`;

export const TrashIcon = styled(TrashIconBase)`
  cursor: pointer;
`;

export const ArrowRightIcon = styled(ArrowRightIconBase)`
  cursor: pointer;
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
`;
