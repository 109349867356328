import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorBoundary, PageLayout } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';
import { SearchQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';
import { ExpandedServerIdContextController } from 'context/expandedServer/expandedServerIdContextController/ExpandedServerIdContextController';

import { SchedulesBar } from './schedulesBar/SchedulesBar';
import { SchedulesListContainer } from './schedulesList/SchedulesListContainer';
import { SchedulesHistoryContainer } from './schedulesHistory/SchedulesHistoryContainer';
import { SchedulesExceptionsContainer } from './schedulesExceptions/SchedulesExceptionsContainer';
import { AddScheduleContainer } from './schedulesBar/addSchedule/AddScheduleContainer';
import { AddExceptionContainer } from './schedulesExceptions/addException/AddExceptionContainer';

export const Schedules = () => {
  const { formatMessage } = useLocale();
  const [schedulesTableSearchQuery, setSchedulesTableSearchQuery] = useState('');
  const location = useLocation();
  const isListView = useIsExactPath(AppRoute.schedules);

  const currentPage = {
    [AppRoute.schedules]: <SchedulesListContainer schedulesTableSearchQuery={schedulesTableSearchQuery} />,
    [AppRoute.schedulesExceptions]: <SchedulesExceptionsContainer />,
    [AppRoute.schedulesHistory]: <SchedulesHistoryContainer />,
  }[location.pathname];

  const addComponent = {
    [AppRoute.schedules]: <AddScheduleContainer />,
    [AppRoute.schedulesExceptions]: <AddExceptionContainer />,
    [AppRoute.schedulesHistory]: null,
  }[location.pathname];

  return (
    <ErrorBoundary>
      <ExpandedServerIdContextController>
        <PageLayout
          header={formatMessage({ id: AppMessages['schedules.title'] })}
          renderContent={() => (
            <>
              <SchedulesBar
                searchQueryKey={SearchQueryKeysEnum.WANTED}
                setTableSearchQuery={isListView ? setSchedulesTableSearchQuery : undefined}
                addComponent={addComponent}
              />

              {currentPage}
            </>
          )}
        />
      </ExpandedServerIdContextController>
    </ErrorBoundary>
  );
};
