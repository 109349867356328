import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';

import { ScheduleTypeProps } from './ScheduleType.types';
import * as Styled from './ScheduleType.styles';

export const ScheduleType = ({ type }: ScheduleTypeProps) => {
  const { formatMessage } = useLocale();

  const typeText = {
    METODY_WERYFIKACJI: formatMessage({ id: AppMessages['schedules.filters.type.verfication'] }),
    PRZEJSCIE: formatMessage({ id: AppMessages['schedules.filters.type.enter'] }),
    WEJSCIE_WYJSCIE: formatMessage({ id: AppMessages['schedules.filters.type.enterExit'] }),
    ODBLOKOWANE: formatMessage({ id: AppMessages['schedules.transition.unlocked'] }),
    WARUNKOWO_ODBLOKOWANE: formatMessage({ id: AppMessages['schedules.transition.conditionallyUnlocked'] }),
    ZABLOKOWANE: formatMessage({ id: AppMessages['schedules.transition.blocked'] }),
  }[type];

  return (
    <Styled.Container type={type}>
      <Styled.Text type={type}>{typeText}</Styled.Text>
    </Styled.Container>
  );
};
