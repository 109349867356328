export enum DaysOfWeekEnum {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum DaysOfWeekMessagesEnum {
  MONDAY = 'common.weekDay.monday',
  TUESDAY = 'common.weekDay.tuesday',
  WEDNESDAY = 'common.weekDay.wednesday',
  THURSDAY = 'common.weekDay.thursday',
  FRIDAY = 'common.weekDay.friday',
  SATURDAY = 'common.weekDay.saturday',
  SUNDAY = 'common.weekDay.sunday',
}
