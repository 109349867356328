import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import { Button } from 'shared-ui';

import { ReactComponent as CheckIcon } from 'assets/icons/check-primary-icon.svg';

const WARNING_CONTAINER_BACKGROUND = '#EAF1FF';

export const DaysMainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ToggleAllDaysButton = styled(Button)`
  width: 250px;
  padding: 0;
  gap: 10px;
`;

export const InfoContainer = styled.div(
  ({ theme: { shape } }) => css`
    background-color: ${WARNING_CONTAINER_BACKGROUND};
    padding: 10px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    border-radius: ${shape.borderRadius}px;
  `,
);

export const InfoText = styled(Typography)(
  ({ theme: { palette } }) => css`
    color: ${palette.primary.main};
    margin-left: 10px;
  `,
);

export const CheckPrimaryIcon = styled(CheckIcon)<{ $disabled: boolean }>(
  ({ $disabled }) => css`
    opacity: ${$disabled ? 0.4 : 1};
  `,
);
