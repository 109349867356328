import styled from 'styled-components';
import { Typography } from '@mui/material';
import { Button } from 'shared-ui';

export const ModalBody = styled.div`
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
`;

export const ErrorTitle = styled(Typography)`
  font-weight: 700;
  text-align: center;
`;

export const ErrorText = styled(Typography)`
  text-align: center;
`;

export const ConfirmButton = styled(Button)`
  padding: 0;
  min-width: 45px !important;
`;
