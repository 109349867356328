import { useEffect, useState } from 'react';
import { usePagination } from 'shared-ui';

import { QueryKeyEnum } from 'core/global.enum';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import { fetchServers } from 'api/servers/servers';

import { SchedulesExceptions } from './SchedulesExceptions';

export const SchedulesExceptionsContainer = () => {
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.SCHEDULES_EXCEPTIONS]: schedulesExceptionsQuery },
    setQuery,
  } = useLocaleStoreQuery();

  const [schedulesExceptionsCount, setSchedulesExceptionsCount] = useState(0);
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination();

  const { data: serversResponse, isLoading } = useQuery([QueryKeyEnum.SERVERS], fetchServers);

  useEffect(() => {
    setQuery(QueryKeyLocalStorageEnum.SCHEDULES_EXCEPTIONS, {
      ...schedulesExceptionsQuery,
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    });
  }, [page, rowsPerPage]);

  return (
    <SchedulesExceptions
      servers={serversResponse?.data}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      schedulesExceptionsCount={schedulesExceptionsCount}
      setSchedulesExceptionsCount={setSchedulesExceptionsCount}
    />
  );
};
