import { useNavigate } from 'react-router';
import { ProblemIconWithTooltip } from 'shared-ui';

import { Table } from 'ui/table/Table';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { ScheduleType } from 'ui/scheduleType/ScheduleType';
import { AppRouteWithParams } from 'routing/AppRoute.enum';

import * as Styled from './SchedulesTableRow.styles';
import { SchedulesTableRowProps } from './SchedulesTableRow.types';
import { DeleteScheduleContainer } from './deleteSchedule/DeleteScheduleContainer';

export const SchedulesTableRow = ({ schedule }: SchedulesTableRowProps) => {
  const { formatMessage } = useLocale();
  const navigate = useNavigate();

  return (
    <>
      <Table.RowItem>{schedule.nazwa}</Table.RowItem>

      <Table.RowItem>
        <ScheduleType type={schedule.typ} />
      </Table.RowItem>

      <Table.RowItem>
        <ProblemIconWithTooltip
          isError={schedule.problemTkd}
          tooltip={formatMessage({ id: AppMessages['schedules.notUpdated.info'] })}
        />
      </Table.RowItem>

      <Table.RowItem>
        <Styled.Wrapper>
          <Styled.TerminalsIcon onClick={() => navigate(AppRouteWithParams.scheduleReaders.get({ id: schedule.id }))} />
          <DeleteScheduleContainer schedule={schedule} />
          <Styled.ArrowRightIcon
            onClick={() =>
              navigate(AppRouteWithParams.scheduleDetails.get({ id: schedule.id }), {
                state: { scheduleName: schedule.nazwa },
              })
            }
          />
        </Styled.Wrapper>
      </Table.RowItem>
    </>
  );
};
