import { Typography } from '@mui/material';

import { useScheduleTypeValues } from 'app/schedules/hooks/useScheduleTypeValues';

import * as Styled from './ScheduleDetailsLegend.styles';
import { ScheduleDetailsLegendProps } from './ScheduleDetailsLegend.types';

export const ScheduleDetailsLegend = ({ scheduleType }: ScheduleDetailsLegendProps) => {
  const { scheduleTypeValues } = useScheduleTypeValues();

  return (
    <Styled.LegendWrapper>
      <Styled.TypeWrapper>
        <Styled.TypeColor />
        <Typography variant="body2">Brak</Typography>
      </Styled.TypeWrapper>

      {Object.values(scheduleTypeValues[scheduleType]).map((type) => (
        <Styled.TypeWrapper key={type.label}>
          <Styled.TypeColor $color={type.color} />
          <Typography variant="body2">{type.label}</Typography>
        </Styled.TypeWrapper>
      ))}
    </Styled.LegendWrapper>
  );
};
