import styled, { css } from 'styled-components';
import { IconButton as IconButtonBase, Typography } from '@mui/material';

import { ReactComponent as ArrowBottomIcon } from 'assets/icons/expand.svg';

export const DailyScheduleWrapper = styled.div(
  ({ theme: { palette, shape } }) => css`
    background-color: ${palette.common.white};
    padding: 8px;
    border-radius: ${shape.borderRadius}px;
    margin-top: 12px;
  `,
);

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const ScheduleTitle = styled(Typography)`
  font-weight: 700;
`;

export const TimeRangeWrapper = styled.div`
  padding: 0 24px;
`;

export const ArrowTopIcon = styled(ArrowBottomIcon)`
  transform: rotate(180deg);
`;

export const IconButton = styled(IconButtonBase)(
  ({ theme: { shape, palette } }) => css`
    border-radius: ${shape.borderRadius}px;
    background-color: ${palette.background.default};
    padding: 2px;
  `,
);

export const CollapseBody = styled.div`
  padding: 20px 0 0;
`;
