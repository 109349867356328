import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { HistoryList } from 'ui/historyList/HistoryList';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { HistoryActionEnum } from 'core/global.enum';

import { WantedHistoryFilters } from './wantedHistoryFilters/WantedHistoryFilters';
import { WantedHistoryProps } from './WantedHistory.types';

export const WantedHistory = ({
  wantedHistoryResponse,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  servers,
  executorSearchQuery,
  setExecutorSearchQuery,
  tkdSearchQuery,
  setTkdSearchQuery,
  ownerSearchQuery,
  setOwnerSearchQuery,
  onClearWantedHistoryQuery,
}: WantedHistoryProps) => {
  const { formatMessage } = useLocale();

  const {
    storedQueries: { wantedHistory: wantedHistoryQuery },
  } = useLocaleStoreQuery();

  const isClearFiltersButtonVisible =
    Object.values(wantedHistoryQuery).some((query) => query) ||
    !!executorSearchQuery ||
    !!tkdSearchQuery ||
    !!ownerSearchQuery;

  return (
    <HistoryList
      data={wantedHistoryResponse?.data || []}
      isLoading={isLoading}
      filters={{
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        isClearButtonVisible: isClearFiltersButtonVisible,
        onClearFilters: onClearWantedHistoryQuery,
        render: () => (
          <WantedHistoryFilters
            servers={servers}
            tkdSearchQuery={tkdSearchQuery}
            executorSearchQuery={executorSearchQuery}
            ownerSearchQuery={ownerSearchQuery}
            setExecutorSearchQuery={setExecutorSearchQuery}
            setOwnerSearchQuery={setOwnerSearchQuery}
            setTkdSearchQuery={setTkdSearchQuery}
          />
        ),
      }}
      pagination={{
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        count: wantedHistoryResponse?.count || 0,
      }}
      renderHistoryItemBody={(history) => (
        <>
          {history.wykonawca && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['wanted.history.label.executor'] })}
              value={`${history.wykonawca.imie} ${history.wykonawca.nazwisko}`}
            />
          )}
          {history.dodaniPosiadacze && (
            <HistoryList.ActionListdBodyItem
              title={formatMessage(
                { id: AppMessages['wanted.history.label.owners'] },
                { count: history.dodaniPosiadacze.length },
              )}
              items={history.dodaniPosiadacze}
              shouldScrollToTop={!!wantedHistoryQuery.posiadacz && !isLoading}
              renderItemBody={(owner) => (
                <HistoryList.RenderedActionListdBodyItem
                  numberPrefix={formatMessage({ id: AppMessages['common.registrationNumber'] })}
                  number={owner.nrEwidencyjny}
                  value={`${owner.imie} ${owner.nazwisko}`}
                />
              )}
            />
          )}
          {(history.tkds || history.tkdsAdded || history.tkdsDeleted) && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['wanted.history.label.serwer'] })}
              value={
                history.tkds
                  ? history.tkds[0].server.nazwa
                  : history.tkdsAdded
                  ? history.tkdsAdded[0].server.nazwa
                  : history.tkdsDeleted
                  ? history.tkdsDeleted[0].server.nazwa
                  : ''
              }
            />
          )}
          {history.tkds && (
            <HistoryList.ActionListdBodyItem
              title={formatMessage(
                {
                  id: AppMessages[
                    history.powod === 'Czytnik zmienił status na Usunięty'
                      ? history.akcja === HistoryActionEnum.USUNIETO
                        ? 'wanted.history.label.readers'
                        : 'wanted.history.label.readersAutoDeleted'
                      : 'wanted.history.label.readers'
                  ],
                },
                { count: history.tkds.length },
              )}
              items={history.tkds}
              shouldScrollToTop={!!wantedHistoryQuery.posiadacz && !isLoading}
              renderItemBody={(tkd) => (
                <HistoryList.RenderedActionListdBodyItem
                  numberPrefix={formatMessage({ id: AppMessages['common.id'] })}
                  number={tkd.numer}
                  value={tkd.nazwa}
                />
              )}
            />
          )}
          {history.tkdsAdded && (
            <HistoryList.ActionListdBodyItem
              title={formatMessage(
                { id: AppMessages['wanted.history.label.addedReaders'] },
                { count: history.tkdsAdded.length },
              )}
              items={history.tkdsAdded}
              shouldScrollToTop={!!wantedHistoryQuery.posiadacz && !isLoading}
              renderItemBody={(tkd) => (
                <HistoryList.RenderedActionListdBodyItem
                  numberPrefix={formatMessage({ id: AppMessages['common.id'] })}
                  number={tkd.numer}
                  value={tkd.nazwa}
                />
              )}
            />
          )}
          {history.tkdsDeleted && (
            <HistoryList.ActionListdBodyItem
              title={formatMessage(
                { id: AppMessages['wanted.history.label.removedReaders'] },
                { count: history.tkdsDeleted.length },
              )}
              items={history.tkdsDeleted}
              shouldScrollToTop={!!wantedHistoryQuery.posiadacz && !isLoading}
              renderItemBody={(tkd) => (
                <HistoryList.RenderedActionListdBodyItem
                  numberPrefix={formatMessage({ id: AppMessages['common.id'] })}
                  number={tkd.numer}
                  value={tkd.nazwa}
                />
              )}
            />
          )}
          {history.informacja &&
            (typeof history.informacja === 'string' ? (
              <HistoryList.ActionBodyItem
                title={formatMessage({ id: AppMessages['wanted.history.label.information'] })}
                value={history.informacja}
              />
            ) : (
              <HistoryList.ActionModifiedFieldBodyItem
                title={formatMessage({ id: AppMessages['wanted.history.label.information'] })}
                oldValue={history.informacja.old}
                newValue={history.informacja.new}
              />
            ))}
          {history.powod && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['wanted.history.label.reason'] })}
              value={history.powod}
            />
          )}
        </>
      )}
    />
  );
};
