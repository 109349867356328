import { Controller, useFormContext } from 'react-hook-form';
import { Modal, Button, Select, Datepicker } from 'shared-ui';
import { useMemo } from 'react';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';
import { Form } from 'ui/form/Form';
import { useRenderMultiAutocompleteOption } from 'ui/multiAutocomplete/hooks/useRenderMultiAutocompleteOption';
import { MultiAutocomplete } from 'ui/multiAutocomplete/MultiAutocomplete';
import { ScheduleTransition } from 'api/schedules/schedules.enum';
import { DateFormat } from 'core/global.enum';

import { AddExceptionForm, AddExceptionProps } from './AddException.types';
import * as Styled from './AddException.styles';

export const AddException = ({
  servers,
  isModalOpen,
  onModalClose,
  onModalOpen,
  onSubmit,
  isLoadingAddException,
  terminals,
  isLoadingTerminals,
}: AddExceptionProps) => {
  const { formatMessage } = useLocale();
  const minDate = useMemo(() => new Date(), []);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid, errors },
    getFieldState,
    watch,
  } = useFormContext<AddExceptionForm>();
  const tkdErrorMessage = getFieldState('tkds').error?.message;
  const { renderMultiAutocompleteOption } = useRenderMultiAutocompleteOption();
  const { serverOptions, scheduleTransitionOptions } = useAppSelectOptions({ servers });

  const isDateSelected = !!watch('data');
  const terminalsOptions = terminals.map((terminal) => ({
    label: terminal.nazwa,
    value: terminal.id,
    number: terminal.numerLogicznyKontrolera,
  }));

  return (
    <>
      <Button variant="contained" color="primary" onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['schedules.exceptions.add.title'] })}
      </Button>

      <Modal
        header={formatMessage({ id: AppMessages['schedules.exceptions.add.title'] })}
        onClose={onModalClose}
        open={isModalOpen}
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={!isValid}
        isLoading={isLoadingAddException}
      >
        <Styled.Grid>
          <Controller
            name="data"
            control={control}
            render={({ field }) => (
              <Datepicker
                {...field}
                required
                label={formatMessage({ id: AppMessages['schedules.exceptions.add.date.label'] })}
                placeholderText={formatMessage({ id: AppMessages['common.datePlaceholder.short'] })}
                withCalendarIcon
                withDatepickerPopover
                isClickable
                withoutTimeInput
                errorMessage={errors?.data?.message}
                minDate={minDate}
                inputFormat={DateFormat.DATE}
                popoverOrigin={{
                  vertical: -80,
                  horizontal: 60,
                }}
              />
            )}
          />
          <Controller
            name="typ"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Select
                {...field}
                label={formatMessage({ id: AppMessages['schedules.exceptions.add.transition.label'] })}
                placeholder={formatMessage({ id: AppMessages['schedules.exceptions.add.transition.placeholder'] })}
                options={scheduleTransitionOptions}
                required
                errorMessage={error?.message}
                withHelperText
                onChange={(e) => {
                  setValue('typ', e.target.value as ScheduleTransition, { shouldDirty: true });
                }}
              />
            )}
          />
          <Form.ControlledSelect
            name="serwerId"
            disabled={!isDateSelected}
            control={control}
            onChange={(e) => {
              setValue('serwerId', e.target.value as string, { shouldDirty: true });
              setValue('tkds', []);
            }}
            label={formatMessage({ id: AppMessages['schedules.exceptions.add.server.label'] })}
            withHelperText
            required
            options={serverOptions}
          />
          <div>
            <Controller
              name="tkds"
              control={control}
              render={({ field }) => (
                <MultiAutocomplete
                  {...field}
                  required
                  withLimitedTags
                  stopRotatePopupIndicator
                  disabled={!isDateSelected}
                  inputSize="big"
                  limitTags={1}
                  onChange={(_, option) => (option ? field.onChange(option) : field.onChange(null))}
                  options={terminalsOptions}
                  loading={isLoadingTerminals}
                  label={formatMessage({ id: AppMessages['schedules.exceptions.add.reader.label'] })}
                  placeholder={formatMessage({ id: AppMessages['common.list.placeholder'] })}
                  renderOption={renderMultiAutocompleteOption}
                  onBlur={(e) => e.preventDefault()}
                />
              )}
            />

            <Styled.ErrorMessage>{tkdErrorMessage}</Styled.ErrorMessage>
          </div>
        </Styled.Grid>
      </Modal>
    </>
  );
};
