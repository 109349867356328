import { ToggleButton } from '@mui/material';
import styled, { css } from 'styled-components';

export const DaysWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const DayButton = styled(ToggleButton)(
  ({ theme: { palette } }) => css`
    border-radius: 50%;
    width: 34px;
    height: 34px;
    border-color: ${palette.primary.main};
    color: ${palette.primary.main};
    padding: 0;

    &.Mui-selected {
      background-color: ${palette.primary.main};
      color: ${palette.common.white};

      &.Mui-disabled {
        background-color: ${palette.grey[600]};
      }

      &:hover {
        background-color: ${palette.primary.main};
      }
    }
  `,
);
