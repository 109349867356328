import { useNavigate } from 'react-router';
import { ProblemIconWithTooltip } from 'shared-ui';
import { format } from 'date-fns';

import { DateFormat } from 'core/global.enum';
import { Table } from 'ui/table/Table';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { ScheduleType } from 'ui/scheduleType/ScheduleType';
import { AppRouteWithParams } from 'routing/AppRoute.enum';

import * as Styled from './SchedulesExceptionsTableRow.styles';
import { SchedulesExceptionsTableRowProps } from './SchedulesExceptionsTableRow.types';
import { DeleteScheduleExceptionContainer } from './deleteScheduleException/DeleteScheduleExceptionContainer';

export const SchedulesExceptionsTableRow = ({ scheduleException }: SchedulesExceptionsTableRowProps) => {
  const { formatMessage } = useLocale();
  const navigate = useNavigate();

  return (
    <>
      <Table.RowItem>{format(new Date(scheduleException.data), DateFormat.DATE)}</Table.RowItem>

      <Table.RowItem>
        <ScheduleType type={scheduleException.typ} />
      </Table.RowItem>

      <Table.RowItem>
        <ProblemIconWithTooltip
          isError={scheduleException.problemTkd}
          tooltip={formatMessage({ id: AppMessages['schedules.exceptions.notUpdated.info'] })}
        />
      </Table.RowItem>

      <Table.RowItem>
        <Styled.Wrapper>
          <Styled.TerminalsIcon
            onClick={() => navigate(AppRouteWithParams.exceptionReaders.get({ id: scheduleException.id }))}
          />
          <DeleteScheduleExceptionContainer scheduleException={scheduleException} />
        </Styled.Wrapper>
      </Table.RowItem>
    </>
  );
};
