import { Table } from 'ui/table/Table';

import { ExceptionTermianalsTableRowProps } from './ExceptionTerminalsTableRow.types';

export const ExceptionTerminalsTableRow = ({ terminal }: ExceptionTermianalsTableRowProps) => {
  return (
    <>
      <Table.RowItem>{terminal.numerLogicznyKontrolera}</Table.RowItem>

      <Table.RowItem>{terminal.nazwa}</Table.RowItem>
    </>
  );
};
