import { EmptyList, useLocale } from 'shared-ui';

import { Table } from 'ui/table/Table';
import { AppMessages } from 'i18n/messages';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { QueryKeyLocalStorageEnum, SchedulesQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';

import { SchedulesExceptionsTableProps } from './SchedulesExceptions.types';
import { SchedulesExceptionsTableHeader } from './schedulesExceptionsTableHeader/SchedulesExceptionsTableHeader';
import { SchedulesExceptionsTableRow } from './schedulesExceptionsTableRow/SchedulesExceptionsTableRow';

export const SchedulesExceptionsTable = ({ schedulesExceptionsResponse }: SchedulesExceptionsTableProps) => {
  const { formatMessage } = useLocale();
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.SCHEDULES_EXCEPTIONS]: scheduleExceptionsQuery },
  } = useLocaleStoreQuery();

  const areSomeFiltersSelected = Object.entries(scheduleExceptionsQuery).some(
    ([key, value]) =>
      ![SchedulesQueryKeysEnum.SORT, SchedulesQueryKeysEnum.LIMIT, SchedulesQueryKeysEnum.OFFSET].includes(
        key as SchedulesQueryKeysEnum,
      ) && !!value,
  );

  return (
    <Table
      gridTemplateColumns="1fr 1fr 1fr 140px"
      data={schedulesExceptionsResponse?.data || []}
      nothingToShowText={
        areSomeFiltersSelected ? (
          <EmptyList
            iconType="loop"
            mainText={formatMessage({ id: AppMessages['schedules.exceptions.noResults.mainText'] })}
            helperText={formatMessage({ id: AppMessages['schedules.exceptions.noResults.helperText'] })}
          />
        ) : (
          formatMessage({ id: AppMessages['schedules.exceptions.noResults.nothingToShowText'] })
        )
      }
      renderHeader={() => <SchedulesExceptionsTableHeader />}
      renderRow={(scheduleException) => <SchedulesExceptionsTableRow scheduleException={scheduleException} />}
      customTableHeight={() => undefined}
    />
  );
};
