import { ErrorBoundary, PageLayout, TOP_BAR_HEIGHT, TopBar, useLocale, useQueryClient } from 'shared-ui';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useLocation, useWindowSize } from 'react-use';

import { ScheduleTypeDescriptionEnum } from 'api/schedules/schedules.enum';
import { QueryKeyEnum } from 'core/global.enum';

import { DaysOfWeekEnum, DaysOfWeekMessagesEnum } from './ScheduleDetails.enum';
import { DailySchedule } from './dailySchedule/DailySchedule';
import { ScheduleDetailsProps } from './ScheduleDetails.types';
import { ScheduleDetailsLegend } from './scheduleDetailsLegend/ScheduleDetailsLegend';
import { SCHEDULE_LEGEND_HEIGHT } from './scheduleDetailsLegend/ScheduleDetailsLegend.styles';

export const MAX_SCHEDULE_PERIODS = 8;
const ADDITIONAL_PAGE_SPACE = 30;

export const ScheduleDetails = ({ scheduleDetails, isLoading }: ScheduleDetailsProps) => {
  const { formatMessage } = useLocale();
  const { height } = useWindowSize();
  const { state } = useLocation();
  const queryClient = useQueryClient();

  return (
    <ErrorBoundary>
      <PageLayout
        withBackButton={{
          onClick: (navigate) => {
            navigate(-1);
            queryClient.removeQueries([QueryKeyEnum.SCHEDULE_DETAILS]);
          },
        }}
        header={state.usr.scheduleName || scheduleDetails.nazwa}
        isLoading={isLoading}
        renderContent={(pageLayoutStylesProps) => (
          <>
            <TopBar gridTemplateColumns="auto">
              <TopBar.Header>{formatMessage({ id: ScheduleTypeDescriptionEnum[scheduleDetails.typ] })}</TopBar.Header>
            </TopBar>

            <ScheduleDetailsLegend scheduleType={scheduleDetails.typ} />

            <OverlayScrollbarsComponent defer options={{ scrollbars: { autoHide: 'scroll' } }}>
              <div
                style={{
                  height:
                    height -
                    pageLayoutStylesProps.headerHeight -
                    pageLayoutStylesProps.paddingBottom -
                    pageLayoutStylesProps.paddingTop -
                    TOP_BAR_HEIGHT -
                    SCHEDULE_LEGEND_HEIGHT -
                    ADDITIONAL_PAGE_SPACE,
                }}
              >
                {Object.entries(scheduleDetails.dni).map(([currentDay, schedulePeriods]) => (
                  <DailySchedule
                    key={currentDay}
                    currentDay={currentDay as DaysOfWeekEnum}
                    label={formatMessage({ id: DaysOfWeekMessagesEnum[currentDay as DaysOfWeekEnum] })}
                    schedulePeriods={schedulePeriods}
                    scheduleType={scheduleDetails.typ}
                    scheduleDetails={scheduleDetails}
                  />
                ))}
              </div>
            </OverlayScrollbarsComponent>
          </>
        )}
      />
    </ErrorBoundary>
  );
};
