import { useEffect, useState } from 'react';
import { Collapse } from '@mui/material';

import { TimeRange } from 'app/schedules/scheduleDetails/dailySchedule/timeRange/TimeRange';
import { ReactComponent as ArrowBottomIcon } from 'assets/icons/expand.svg';
import { SchedulePeriod } from 'api/schedules/schedules.types';
import { useDefaultSchedulePeriod } from 'app/schedules/hooks/useGetDefaultSchedulePeriod';

import { DailyScheduleFormContainer } from './dailyScheduleForm/DailyScheduleFormContainer';
import { DailyScheduleProps } from './DailySchedule.types';
import * as Styled from './DailySchedule.styles';

export const TIME_SLOT = 15;

export const DailySchedule = ({
  label,
  schedulePeriods,
  scheduleType,
  currentDay,
  scheduleDetails,
}: DailyScheduleProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [schedulePeriodsWithUnsaved, setSchedulePeriodsWithUnsaved] = useState<SchedulePeriod[]>(schedulePeriods);

  const { defaultSchedulePeriod } = useDefaultSchedulePeriod();

  useEffect(() => {
    if (!schedulePeriods.length) {
      setSchedulePeriodsWithUnsaved([defaultSchedulePeriod]);
    } else {
      setSchedulePeriodsWithUnsaved(schedulePeriods);
    }
  }, [schedulePeriods]);

  return (
    <Styled.DailyScheduleWrapper>
      <Styled.TitleWrapper>
        <Styled.ScheduleTitle>{label}</Styled.ScheduleTitle>

        <Styled.IconButton size="small" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <Styled.ArrowTopIcon /> : <ArrowBottomIcon />}
        </Styled.IconButton>
      </Styled.TitleWrapper>

      <Styled.TimeRangeWrapper>
        <TimeRange
          schedulePeriod={schedulePeriods.map((period) =>
            period.endTime === '00:00' ? { ...period, endTime: '24:00' } : period,
          )}
          timeSlot={TIME_SLOT}
          scheduleType={scheduleType}
        />

        <Collapse in={isExpanded}>
          <Styled.CollapseBody>
            {schedulePeriodsWithUnsaved.map((period, index) => (
              <DailyScheduleFormContainer
                key={period.id}
                period={period}
                rowNumber={Math.abs(index - schedulePeriodsWithUnsaved.length)}
                scheduleType={scheduleType}
                currentDay={currentDay}
                scheduleDetails={scheduleDetails}
                schedulePeriodsWithUnsaved={schedulePeriodsWithUnsaved}
                setSchedulePeriodsWithUnsaved={setSchedulePeriodsWithUnsaved}
              />
            ))}
          </Styled.CollapseBody>
        </Collapse>
      </Styled.TimeRangeWrapper>
    </Styled.DailyScheduleWrapper>
  );
};
