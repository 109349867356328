import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';
import { deleteScheduleTerminals } from 'api/schedules/schedules';

import { DeleteScheduleTerminals } from './DeleteScheduleTerminals';
import { DeleteScheduleTerminalsContainerProps } from './DeleteScheduleTerminals.types';

export const DeleteScheduleTerminalsContainer = ({
  scheduleId,
  checkedTerminals,
  setCheckedTerminals,
  terminalsCount,
}: DeleteScheduleTerminalsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const isMultiDelete = checkedTerminals.length > 1;

  const { mutate: onDeleteScheduleTerminals, isLoading } = useMutation(deleteScheduleTerminals, {
    onSuccess: (_, { body }) => {
      body.length > 1
        ? toast.dark(formatMessage({ id: AppMessages['schedule.terminals.delete.multi.success'] }))
        : toast.dark(formatMessage({ id: AppMessages['schedule.terminals.delete.single.success'] }));

      queryClient.invalidateQueries([QueryKeyEnum.SCHEDULE_TERMINALS, scheduleId]);
      queryClient.invalidateQueries([QueryKeyEnum.TERMINALS]);

      setCheckedTerminals([]);
      onModalClose();
    },
  });

  const onSubmit = () => {
    onDeleteScheduleTerminals({
      id: scheduleId,
      body: checkedTerminals.map(({ id }) => id),
    });
  };

  return (
    <DeleteScheduleTerminals
      checkedTerminals={checkedTerminals}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
      isMultiDelete={isMultiDelete}
      terminalsCount={terminalsCount}
    />
  );
};
