import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import { Select as SelectBase, Button as ButtonBase } from 'shared-ui';

import { ReactComponent as TrashIconBase } from 'assets/icons/primary-trash.svg';
import { ReactComponent as InfoIconBase } from 'assets/icons/info-primary.svg';

export const TimeRangeNumber = styled(Typography)(
  ({ theme: { palette, shape } }) => css`
    width: 32px;
    height: 32px;
    background-color: ${palette.background.default};
    border-radius: ${shape.borderRadius}px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 9px;
  `,
);

export const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FormPartWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

export const InputWithLabelWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const InputLabel = styled(Typography)`
  font-weight: 700;
  margin-top: 16px;
`;

export const Select = styled(SelectBase)`
  min-width: 250px;
`;

export const Button = styled(ButtonBase)`
  margin-top: 3px;
  min-width: 100px;
  font-size: 14px;
`;

export const SmallButton = styled(ButtonBase)`
  margin-top: 3px;
  min-width: 45px;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
`;

export const TrashIcon = styled(TrashIconBase)`
  margin-left: -15px;
  margin-right: 5px;
`;

export const AddButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const InfoPrimaryIcon = styled(InfoIconBase)`
  margin-top: 7px;
`;
