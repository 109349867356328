import { formatISO } from 'date-fns';
import { Autocomplete, Datepicker } from 'shared-ui';

import { HistoryDatepickerWithModal } from 'reusable/historyDatepickerWithModal/HistoryDatepickerWithModal';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';
import { HistoryActionAutocomplete } from 'reusable/historyActionAutocomplete/HistoryActionAutocomplete';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { SchedulesHistoryQuery } from 'context/query/queryContext/QueryContext.types';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { ScheduleHistoryTypeFilterEnum } from 'api/schedules/schedules.enum';
import { DateFormat } from 'core/global.enum';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';

import { SchedulesHistoryFiltersProps } from './SchedulesHistoryFilters.types';
import * as Styled from './SchedulesHistoryFilters.styles';

export const SchedulesHistoryFilters = ({
  servers,
  executorSearchQuery,
  setExecutorSearchQuery,
  tkdSearchQuery,
  setTkdSearchQuery,
  scheduleSearchQuery,
  setScheduleSearchQuery,
}: SchedulesHistoryFiltersProps) => {
  const { formatMessage } = useLocale();

  const {
    storedQueries: { schedulesHistory: schedulesHistoryQuery },
    setQuery,
  } = useLocaleStoreQuery();

  const setScheduleHistoryQuery = (query: SchedulesHistoryQuery) => {
    setQuery(QueryKeyLocalStorageEnum.SCHEDULES_HISTORY, { ...schedulesHistoryQuery, ...query });
  };

  const serversOptions =
    servers?.map((server) => ({
      label: `${server.nazwa}`,
      value: server.id,
    })) || [];

  const { scheduleHistoryTypeFilterOptions } = useAppSelectOptions();

  return (
    <>
      <HistoryDatepickerWithModal
        dates={[
          schedulesHistoryQuery.dataOd ? new Date(schedulesHistoryQuery.dataOd) : null,
          schedulesHistoryQuery.dataDo ? new Date(schedulesHistoryQuery.dataDo) : null,
        ]}
        setDates={(dates) =>
          setScheduleHistoryQuery({
            dataOd: dates[0] ? formatISO(dates[0]) : undefined,
            dataDo: dates[1] ? formatISO(dates[1]) : undefined,
          })
        }
      />

      <HistoryActionAutocomplete
        selectedAction={schedulesHistoryQuery.akcja}
        onChangeAction={(action) => setScheduleHistoryQuery({ akcja: action })}
        historyType="schedules"
      />

      <Autocomplete
        onChange={(_, option) => setScheduleHistoryQuery({ serwer: option || undefined, tkd: undefined })}
        value={serversOptions.find((server) => schedulesHistoryQuery.serwer?.value === server.value) || null}
        options={serversOptions}
        loadingText={formatMessage({ id: AppMessages['common.loading'] })}
        noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
        placeholder={formatMessage({ id: AppMessages['schedules.history.filters.server'] })}
        inputVariant="outlined"
        inputSize="big"
        isOptionEqualToValue={(option, value) => option.value === value.value}
        stopRotatePopupIndicator
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            <Styled.AutocompleteOption>{option.label}</Styled.AutocompleteOption>
          </li>
        )}
      />

      <TableSearchContainer
        withoutToggle
        inputPlaceholder={formatMessage({ id: AppMessages['schedules.history.filters.reader'] })}
        searchBy="terminals"
        tableSearchQuery={tkdSearchQuery}
        setTableSearchQuery={setTkdSearchQuery}
        selectedValue={schedulesHistoryQuery.tkd}
        setSelectedValue={(option) => setScheduleHistoryQuery({ tkd: option || undefined })}
        inputSize="big"
        isDisabled={!schedulesHistoryQuery.serwer}
        serverId={schedulesHistoryQuery.serwer?.value as string}
      />

      <Autocomplete
        onChange={(_, option) => setScheduleHistoryQuery({ typ: option?.value as ScheduleHistoryTypeFilterEnum })}
        value={scheduleHistoryTypeFilterOptions.find((type) => schedulesHistoryQuery.typ === type.value) || null}
        options={scheduleHistoryTypeFilterOptions}
        loadingText={formatMessage({ id: AppMessages['common.loading'] })}
        noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
        placeholder={formatMessage({ id: AppMessages['schedules.history.filters.type'] })}
        inputVariant="outlined"
        inputSize="big"
        isOptionEqualToValue={(option, value) => option.value === value.value}
        stopRotatePopupIndicator
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            <Styled.AutocompleteOption>{option.label.toLowerCase()}</Styled.AutocompleteOption>
          </li>
        )}
      />

      <TableSearchContainer
        withoutToggle
        inputPlaceholder={formatMessage({ id: AppMessages['schedules.history.filters.schedule'] })}
        searchBy="schedules"
        tableSearchQuery={scheduleSearchQuery}
        setTableSearchQuery={setScheduleSearchQuery}
        selectedValue={schedulesHistoryQuery.nazwa}
        setSelectedValue={(option) =>
          setScheduleHistoryQuery({
            nazwa: option ? { ...option, value: option.value } : undefined,
          })
        }
        inputSize="big"
        isDisabled={!schedulesHistoryQuery.typ || schedulesHistoryQuery.typ === ScheduleHistoryTypeFilterEnum.WYJATEK}
      />

      <Datepicker
        value={schedulesHistoryQuery.wyjatek ? new Date(schedulesHistoryQuery.wyjatek) : ''}
        onChange={(value) => {
          setScheduleHistoryQuery({ wyjatek: value ? (value as unknown as string) : undefined });
        }}
        withCalendarIcon
        toggleCalendarRemoveIcon
        withoutHelperText
        withoutErrorMessage
        disabled={!schedulesHistoryQuery.typ || schedulesHistoryQuery.typ !== ScheduleHistoryTypeFilterEnum.WYJATEK}
        isClickable={!!schedulesHistoryQuery.typ && schedulesHistoryQuery.typ === ScheduleHistoryTypeFilterEnum.WYJATEK}
        withDatepickerPopover={
          !!schedulesHistoryQuery.typ && schedulesHistoryQuery.typ === ScheduleHistoryTypeFilterEnum.WYJATEK
        }
        popoverOrigin={{
          vertical: 40,
          horizontal: -220,
        }}
        inputVariant="outlined"
        placeholderText={formatMessage({ id: AppMessages['schedules.history.filters.exception'] })}
        smallPlaceholderFontSize
        withoutTimeInput
        inputFormat={DateFormat.DATE}
        disableDateTyping
        calendarIconGrayColor
      />

      <TableSearchContainer
        withoutToggle
        inputPlaceholder={formatMessage({ id: AppMessages['schedules.history.filters.executor'] })}
        searchBy="users"
        tableSearchQuery={executorSearchQuery}
        setTableSearchQuery={setExecutorSearchQuery}
        selectedValue={schedulesHistoryQuery.query}
        setSelectedValue={(option) => setScheduleHistoryQuery({ query: option || undefined })}
        inputSize="big"
      />
    </>
  );
};
