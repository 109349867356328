import { forwardRef, useEffect, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopTimePicker } from '@mui/x-date-pickers';
import { format, isValid, startOfDay } from 'date-fns';

import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { theme } from 'styles/theme';

import { TimePickerProps } from './TimePicker.types';
import { INPUT_WIDTH } from './TimePicker.styles';
import { TimeViewEnum } from './TimePicker.enum';
import * as Styled from './TimePicker.styles';

export const MINUTES_STEP_ERROR_NAME = 'minutesStep';

export const TimePicker = forwardRef(
  (
    { value, onChange, disabled, minutesStep, errorMessage, onError, placeholder, ...props }: TimePickerProps,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentTimeView, setCurrentTimeView] = useState<TimeViewEnum>(TimeViewEnum.HOURS);

    useEffect(() => {
      setCurrentTimeView(TimeViewEnum.HOURS);
    }, [isOpen]);

    return (
      <div>
        <DesktopTimePicker
          value={value}
          onChange={onChange}
          ref={ref}
          {...props}
          renderInput={(params) => (
            <div style={{ width: INPUT_WIDTH }}>
              <Styled.Input
                {...params}
                label={undefined}
                placeholder={undefined}
                ref={params.inputRef}
                rightIcon={<ClockIcon onClick={() => !disabled && setIsOpen(true)} />}
                errorMessage={errorMessage}
                disabled={disabled}
                inputProps={{
                  ...params.inputProps,
                  placeholder,
                }}
                $isError={!!errorMessage}
              />
            </div>
          )}
          PopperProps={{
            placement: 'bottom-start',
          }}
          PaperProps={{
            style: {
              backgroundColor: theme.palette.common.white,
              boxShadow: 'none',
              border: `1px solid ${theme.palette.grey[500]}`,
            },
          }}
          minutesStep={minutesStep}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          disabled={disabled}
          components={{
            PaperContent: () => (
              <div>
                <Styled.TimeButtonsWrapper>
                  <Styled.TimeButton
                    onClick={() => setCurrentTimeView(TimeViewEnum.HOURS)}
                    $isActive={currentTimeView === TimeViewEnum.HOURS}
                  >
                    {format(value && isValid(value) ? value : startOfDay(new Date()), 'HH')}
                  </Styled.TimeButton>
                  :
                  <Styled.TimeButton
                    onClick={() => setCurrentTimeView(TimeViewEnum.MINUTES)}
                    $isActive={currentTimeView === TimeViewEnum.MINUTES}
                  >
                    {format(value && isValid(value) ? value : startOfDay(new Date()), 'mm')}
                  </Styled.TimeButton>
                </Styled.TimeButtonsWrapper>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Styled.ClockPicker
                    views={['hours', 'minutes']}
                    view={currentTimeView}
                    date={!isValid(value) ? startOfDay(new Date()) : value}
                    onChange={(newDate) => onChange(newDate as Date | null)}
                    minutesStep={minutesStep}
                    onViewChange={() => setCurrentTimeView(TimeViewEnum.MINUTES)}
                  />
                </LocalizationProvider>
              </div>
            ),
          }}
        />
      </div>
    );
  },
);
