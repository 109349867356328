import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';
import * as Styled from '../DailyScheduleForm.styles';

import { DeletePeriodProps } from './DeletePeriod.types';

export const DeletePeriod = ({ onSubmit, onModalClose, onModalOpen, isModalOpen, isLoading }: DeletePeriodProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Styled.Button variant="outlined" onClick={onModalOpen}>
        <Styled.TrashIcon /> {formatMessage({ id: AppMessages['common.delete'] })}
      </Styled.Button>

      <ActionModal
        header={formatMessage({ id: AppMessages['schedule.details.delete.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        onSubmit={onSubmit}
        submitButtonDisabled={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['common.delete'] })}
        size="medium"
      >
        <ActionModal.Text>{formatMessage({ id: AppMessages['schedule.details.delete.text'] })}</ActionModal.Text>
      </ActionModal>
    </>
  );
};
