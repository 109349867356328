import { useLocation } from 'react-router-dom';
import { TopBar } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';
import { useSelectedSearchOptions } from 'hooks/useSelectedSearchOptions/useSelectedSearchOptions';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';
import { useExpandedServerId } from 'hooks/useExpandedServerId/useExpandedServerId';

import { SchedulesBarProps } from './SchedulesBar.types';

export const SchedulesBar = ({ searchQueryKey, setTableSearchQuery, addComponent }: SchedulesBarProps) => {
  const { formatMessage } = useLocale();
  const location = useLocation();

  const { searchOption, setSelectedSearchOption } = useSelectedSearchOptions(searchQueryKey, setTableSearchQuery);
  const { expandedServerId } = useExpandedServerId();

  return (
    <TopBar gridTemplateColumns="auto auto auto 1fr auto">
      <TopBar.Link linkTo={AppRoute.schedules} isActive={location.pathname === AppRoute.schedules}>
        {formatMessage({ id: AppMessages['schedules.list'] })}
      </TopBar.Link>

      <TopBar.Link linkTo={AppRoute.schedulesExceptions} isActive={location.pathname === AppRoute.schedulesExceptions}>
        {formatMessage({ id: AppMessages['schedules.exceptions'] })}
      </TopBar.Link>

      <TopBar.Link linkTo={AppRoute.schedulesHistory} isActive={location.pathname === AppRoute.schedulesHistory}>
        {formatMessage({ id: AppMessages['schedules.history'] })}
      </TopBar.Link>

      {setTableSearchQuery ? (
        <>
          <TopBar.Search>
            <TableSearchContainer
              searchIconTestId="page-search"
              inputPlaceholder={formatMessage({ id: AppMessages['schedules.search'] })}
              searchBy="serverSchedules"
              setTableSearchQuery={setTableSearchQuery}
              selectedValue={searchOption}
              setSelectedValue={setSelectedSearchOption}
              serverId={expandedServerId}
            />
          </TopBar.Search>
        </>
      ) : (
        <TopBar.EmptyBarWithLine />
      )}
      {addComponent && <TopBar.ButtonsWrapper>{addComponent}</TopBar.ButtonsWrapper>}
    </TopBar>
  );
};
