import { useEffect } from 'react';
import { Tooltip, useLocale } from 'shared-ui';
import { Controller, useFormContext } from 'react-hook-form';
import { getHours, getMinutes } from 'date-fns';

import { TimePicker } from 'ui/timePicker/TimePicker';
import { AppMessages } from 'i18n/messages';
import { TIME_SLOT } from '../DailySchedule';
import { useScheduleTypeValues } from 'app/schedules/hooks/useScheduleTypeValues';
import { MAX_SCHEDULE_PERIODS } from '../../ScheduleDetails';
import { useDefaultSchedulePeriod } from 'app/schedules/hooks/useGetDefaultSchedulePeriod';
import { ScheduleTypeSingularDescriptionEnum } from 'api/schedules/schedules.enum';
import { getFullyDefinedDays } from 'app/schedules/utils/getFullyDefinedDays';

import { PeriodSettings } from './periodSettings/PeriodSettings';
import { DeletePeriodContainer } from './deletePeriod/DeletePeriodContainer';
import { DailyScheduleFormProps, DailyScheduleFormBody } from './DailyScheduleForm.types';
import * as Styled from './DailyScheduleForm.styles';

export const DailyScheduleForm = ({
  rowNumber,
  scheduleType,
  onSubmit,
  scheduleDetails,
  currentDay,
  schedulePeriodsWithUnsaved,
  setSchedulePeriodsWithUnsaved,
  isLoadingAddingSchedulePeriod,
  period,
}: DailyScheduleFormProps) => {
  const { formatMessage } = useLocale();

  const { control, handleSubmit, trigger, formState, watch } = useFormContext<DailyScheduleFormBody>();

  const { endTime } = watch();

  const isNewForm = period.startTime === null && period.endTime === null;

  const { scheduleTypeValues } = useScheduleTypeValues();
  const { defaultSchedulePeriod } = useDefaultSchedulePeriod();

  const scheduleTypeOptions = Object.entries(scheduleTypeValues[scheduleType]).map(([key, value]) => ({
    value: key,
    label: value.label,
  }));

  const onClickAddNewPeriodButton = () => {
    setSchedulePeriodsWithUnsaved((periods) => [defaultSchedulePeriod, ...periods]);
  };

  const onClickCancelPeriodButton = () => {
    setSchedulePeriodsWithUnsaved((periods) => periods.filter((_, index) => index !== 0));
  };

  const fullyDefinedDays = getFullyDefinedDays(scheduleDetails.dni);

  const isLastPeriod = schedulePeriodsWithUnsaved.length === rowNumber;
  const isOnlyOnePeriod = schedulePeriodsWithUnsaved.length === 1;
  const areMaxPeriodsDefined = scheduleDetails.dni[currentDay].length === MAX_SCHEDULE_PERIODS;
  const isDayFullyDefined = fullyDefinedDays.includes(currentDay);

  useEffect(() => {
    if (endTime && getHours(endTime) === 0 && getMinutes(endTime) === 0) {
      trigger('startTime');
    }
  }, [endTime]);

  return (
    <Styled.FormWrapper key={period.id}>
      <Styled.FormPartWrapper>
        <Styled.TimeRangeNumber variant="body2">{`${rowNumber}.`}</Styled.TimeRangeNumber>

        <Styled.InputWithLabelWrapper>
          <Styled.InputLabel variant="body2">{formatMessage({ id: AppMessages['common.from'] })}</Styled.InputLabel>

          <Controller
            name="startTime"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TimePicker
                {...field}
                disabled={!isNewForm}
                minutesStep={TIME_SLOT}
                onChange={(e) => {
                  if (formState.errors['endTime']?.type === 'endTime-after-startTime') {
                    trigger('endTime');
                  }
                  field.onChange(e);
                }}
                errorMessage={error?.message}
                placeholder="00:00"
              />
            )}
          />
        </Styled.InputWithLabelWrapper>

        <Styled.InputWithLabelWrapper>
          <Styled.InputLabel variant="body2">{formatMessage({ id: AppMessages['common.to'] })}</Styled.InputLabel>

          <Controller
            name="endTime"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TimePicker
                {...field}
                disabled={!isNewForm}
                minutesStep={TIME_SLOT}
                onChange={(e) => {
                  field.onChange(e);
                  if (formState.errors['startTime']?.type === 'startTime-before-endTime') {
                    trigger('startTime');
                  }
                }}
                errorMessage={error?.message}
                placeholder="00:00"
              />
            )}
          />
        </Styled.InputWithLabelWrapper>

        <Styled.InputWithLabelWrapper>
          <Styled.InputLabel variant="body2">
            {formatMessage({ id: ScheduleTypeSingularDescriptionEnum[scheduleType] })}
          </Styled.InputLabel>

          <Controller
            name="metodaWeryfikacji"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Styled.Select
                {...field}
                placeholder={formatMessage({ id: AppMessages['common.list.placeholder'] })}
                options={scheduleTypeOptions}
                disabled={!isNewForm}
                size="small"
                withHelperText={!!error?.message}
                errorMessage={error?.message}
              />
            )}
          />
        </Styled.InputWithLabelWrapper>
      </Styled.FormPartWrapper>

      <Styled.FormPartWrapper>
        <>
          {isNewForm ? (
            <>
              <PeriodSettings scheduleDetails={scheduleDetails} currentDay={currentDay} />

              <Styled.Button
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                isLoading={isLoadingAddingSchedulePeriod}
              >
                {formatMessage({ id: AppMessages['common.save'] })}
              </Styled.Button>

              <Styled.Button onClick={onClickCancelPeriodButton} variant="outlined" disabled={isOnlyOnePeriod}>
                {formatMessage({ id: AppMessages['common.cancel'] })}
              </Styled.Button>
            </>
          ) : (
            <DeletePeriodContainer selectedPeriod={period} />
          )}

          {isLastPeriod && (
            <Styled.AddButtonWrapper>
              <Styled.SmallButton
                onClick={onClickAddNewPeriodButton}
                disabled={isNewForm || areMaxPeriodsDefined || isDayFullyDefined}
                variant="outlined"
              >
                +
              </Styled.SmallButton>

              {(areMaxPeriodsDefined || isDayFullyDefined) && (
                <Tooltip title={formatMessage({ id: AppMessages['schedule.details.maxPeriodsInfo'] })}>
                  <Styled.InfoPrimaryIcon />
                </Tooltip>
              )}
            </Styled.AddButtonWrapper>
          )}
        </>
      </Styled.FormPartWrapper>
    </Styled.FormWrapper>
  );
};
