import { MutationFn, UseQueryFn } from 'shared-ui';

import { PaginatedResponse } from 'core/global.types';
import { Terminal } from 'api/terminals/terminals.types';
import { Server } from 'api/servers/servers.types';

import {
  AddScheduleBody,
  AddSchedulePeriodBody,
  AddScheduleTerminalsBody,
  DeleteScheduleTerminalsBody,
  Schedule,
  ScheduleDetails,
  ScheduleException,
  SchedulesHistory,
  ScheduleDetailsWithPeriods,
  AddExceptionBody,
} from './schedules.types';

//TODO: Sprawdzic endpoint gdy bedzie gotowy
export const fetchSchedules: UseQueryFn<string, PaginatedResponse<Schedule>> = (id) => {
  return {
    endpoint: `/harmonogram/rcp/${id}`,
  };
};

export const addSchedule: MutationFn<AddScheduleBody, string> = (body) => {
  return {
    endpoint: '/harmonogram',
    method: 'POST',
    body,
  };
};

//TODO: Sprawdzic endpoint gdy bedzie gotowy
export const fetchScheduleTerminals: UseQueryFn<string, PaginatedResponse<Terminal>> = (id) => {
  return {
    endpoint: `/tkd`,
  };
};

//TODO: Sprawdzic endpoint gdy bedzie gotowy
export const deleteSchedule: MutationFn<string, void> = (id) => {
  return {
    endpoint: `/harmonogram/${id}`,
    method: 'DELETE',
  };
};

export const fetchSchedulesExceptions: UseQueryFn<string, PaginatedResponse<ScheduleException>> = (id) => {
  return {
    endpoint: `/wyjatek/rcp/${id}`,
  };
};

export const fetchScheduleExceptionTerminals: UseQueryFn<string, PaginatedResponse<Terminal>> = (id) => {
  return {
    endpoint: `/wyjatek/${id}/tkd`,
  };
};

//TODO: Sprawdzic endpoint gdy bedzie gotowy
export const deleteScheduleException: MutationFn<string, void> = (id) => {
  return {
    endpoint: `/wyjatek/${id}`,
    method: 'DELETE',
  };
};

export const fetchScheduleDetails: UseQueryFn<string, ScheduleDetails> = (id) => {
  return {
    endpoint: `harmonogram/${id}`,
  };
};

//TODO: Sprawdzic endpoint gdy bedzie gotowy
export const addSchedulePeriod: MutationFn<{ id: string; body: AddSchedulePeriodBody }, string> = ({ id, body }) => {
  return {
    endpoint: `/harmonogram/${id}/okres`,
    method: 'POST',
    body,
  };
};

//TODO: Sprawdzic endpoint gdy bedzie gotowy
export const deleteSchedulePeriod: MutationFn<string, void> = (id) => {
  return {
    endpoint: `/harmonogram/okres/${id}`,
    method: 'DELETE',
  };
};

//TODO: Sprawdzic endpoint gdy bedzie gotowy
export const fetchSchedulesHistory: UseQueryFn<void, PaginatedResponse<SchedulesHistory>> = () => {
  return {
    endpoint: `/harmonogram/historia`,
  };
};

//TODO: Sprawdzic endpoint gdy bedzie gotowy
export const fetchAllSchedulesNames: UseQueryFn<void, PaginatedResponse<string>> = () => {
  return {
    endpoint: `/harmonogram/historia/nazwa`,
  };
};

export const fetchServersForSchedule: UseQueryFn<string, PaginatedResponse<Server>> = (id) => {
  return {
    endpoint: `/harmonogram/${id}/rcp`,
  };
};

export const fetchScheduleDetailsTerminals: UseQueryFn<
  {
    scheduleId: string;
    serverId: string;
  },
  PaginatedResponse<Terminal>
> = (body) => {
  return {
    endpoint: `/harmonogram/${body?.scheduleId}/rcp/${body?.serverId}`,
  };
};

export const addScheduleTerminals: MutationFn<AddScheduleTerminalsBody, void> = ({ id, body }) => {
  return {
    endpoint: `batch/harmonogram/${id}/tkd`,
    method: 'POST',
    body,
  };
};

export const deleteScheduleTerminals: MutationFn<DeleteScheduleTerminalsBody, void> = ({ id, body }) => {
  return {
    endpoint: `batch/harmonogram/${id}/tkd`,
    method: 'DELETE',
    body,
  };
};

export const fetchScheduleDetailsWithPeriods: UseQueryFn<string, ScheduleDetailsWithPeriods> = (id) => {
  return {
    endpoint: `harmonogram/${id}/okres`,
  };
};

export const addException: MutationFn<AddExceptionBody, string> = (body) => {
  return {
    endpoint: '/wyjatek',
    method: 'POST',
    body,
  };
};
