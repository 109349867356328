import { Modal, useLocale } from 'shared-ui';

import { ReactComponent as CloudIcon } from 'assets/icons/cloud.svg';
import { AppMessages } from 'i18n/messages';

import { AddPeriodErrorProps } from './AddPeriodError.types';
import * as Styled from './AddPeriodError.styles';

export const AddPeriodError = ({ isModalOpen, onModalClose }: AddPeriodErrorProps) => {
  const { formatMessage } = useLocale();

  return (
    <Modal open={isModalOpen} onClose={onModalClose} type="plain" size="xxsmall">
      <Styled.ModalBody>
        <CloudIcon />

        <Styled.ErrorTitle>{formatMessage({ id: AppMessages['schedule.details.addErrorTitle'] })}</Styled.ErrorTitle>

        <Styled.ErrorText>{formatMessage({ id: AppMessages['schedule.details.addErrorText'] })}</Styled.ErrorText>

        <Styled.ConfirmButton variant="contained" size="small" onClick={onModalClose}>
          {formatMessage({ id: AppMessages['common.ok'] })}
        </Styled.ConfirmButton>
      </Styled.ModalBody>
    </Modal>
  );
};
