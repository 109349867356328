import { ProblemTkdDescriptionEnum } from 'api/cards/cards.enum';
import { ScheduleTypeDescriptionEnum, ScheduleTypeEnum } from 'api/schedules/schedules.enum';
import {
  QueryKeyLocalStorageEnum,
  SchedulesNameSortEnum,
  SchedulesQueryKeysEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';

export const SchedulesTableHeader = () => {
  const { formatMessage } = useLocale();

  const problemTkdFilterOptions = [
    {
      name: formatMessage({ id: AppMessages[ProblemTkdDescriptionEnum.true] }),
      filterBy: 'true',
    },
    {
      name: formatMessage({ id: AppMessages[ProblemTkdDescriptionEnum.false] }),
      filterBy: 'false',
    },
  ];

  const modFilterOptions = Object.values(ScheduleTypeEnum).map((option) => ({
    name: formatMessage({ id: ScheduleTypeDescriptionEnum[option] }),
    filterBy: option,
  }));

  return (
    <>
      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.SCHEDULES}
        sortBy={SchedulesNameSortEnum}
        queryHeader={formatMessage({ id: AppMessages['schedules.filters.name'] })}
      />

      <Table.HeaderFilterItem
        queryKey={QueryKeyLocalStorageEnum.SCHEDULES}
        queryName={SchedulesQueryKeysEnum.TYP}
        filterItems={modFilterOptions}
        queryHeader={formatMessage({ id: AppMessages['schedules.filters.type'] })}
        queryHeaderDescriptions={ScheduleTypeDescriptionEnum}
      />

      <Table.HeaderFilterItem
        queryKey={QueryKeyLocalStorageEnum.SCHEDULES}
        queryName={SchedulesQueryKeysEnum.PROBLEM_TKD}
        filterItems={problemTkdFilterOptions}
        queryHeader={formatMessage({ id: AppMessages['schedules.filters.reader'] })}
        queryHeaderDescriptions={ProblemTkdDescriptionEnum}
      />
    </>
  );
};
