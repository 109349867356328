import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePagination } from 'shared-ui';

import { QueryKeyEnum } from 'core/global.enum';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import { fetchServersForSchedule } from 'api/schedules/schedules';

import { ScheduleTerminals } from './ScheduleTerminals';

export const ScheduleTerminalsContainer = () => {
  const { id: scheduleId } = useParams<{ id: string }>();

  const {
    storedQueries: { [QueryKeyLocalStorageEnum.SCHEDULE_TERMINALS]: scheduleTerminalsQuery },
    setQuery,
  } = useLocaleStoreQuery();

  const [terminalsCount, setTerminalsCount] = useState(0);
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination();

  const { data: serversResponse, isLoading } = useQuery([QueryKeyEnum.SCHEDULE_TERMINALS, scheduleId], () =>
    fetchServersForSchedule(scheduleId),
  );

  useEffect(() => {
    setQuery(QueryKeyLocalStorageEnum.SCHEDULE_TERMINALS, {
      ...scheduleTerminalsQuery,
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    });
  }, [page, rowsPerPage]);

  return (
    <ScheduleTerminals
      servers={serversResponse?.data}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      terminalsCount={terminalsCount}
      setTerminalsCount={setTerminalsCount}
      scheduleId={scheduleId || ''}
    />
  );
};
