import { addMinutes, format } from 'date-fns';

export const generateTimeSlots = (interval: number) => {
  const totalMinutesInDay = 24 * 60;
  const numberOfSlots = Math.floor(totalMinutesInDay / interval);

  const times = Array.from({ length: numberOfSlots }, (_, index) => {
    const currentTime = addMinutes(new Date(0, 0, 0, 0, 0), index * interval);
    return format(currentTime, 'HH:mm');
  });

  times.push('24:00');

  return times;
};
