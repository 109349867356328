import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { usePagination } from 'shared-ui';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import { fetchScheduleExceptionTerminals } from 'api/schedules/schedules';

import { ExceptionTerminals } from './ExceptionTerminals';

export const ExceptionTerminalsContainer = () => {
  const {
    storedQueries: { scheduleExceptionTerminals: scheduleExceptionTerminalsQuery },
  } = useLocaleStoreQuery();
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination();
  const { id } = useParams<{ id: string }>();

  const { data: terminalsResponse, isLoading } = useQuery(
    [QueryKeyEnum.SCHEDULE_EXCEPTION_TERMINALS, scheduleExceptionTerminalsQuery, page, rowsPerPage, id],
    () => fetchScheduleExceptionTerminals(id),
    {
      args: {
        ...scheduleExceptionTerminalsQuery,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      },
    },
  );

  useEffect(() => {
    setPage(0);
  }, [scheduleExceptionTerminalsQuery]);

  return (
    <ExceptionTerminals
      terminalsResponse={terminalsResponse}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
    />
  );
};
