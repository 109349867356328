import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';
import { deleteScheduleException } from 'api/schedules/schedules';

import { DeleteScheduleException } from './DeleteScheduleException';
import { DeletScheduleExceptionContainerProps } from './DeleteScheduleException.types';

export const DeleteScheduleExceptionContainer = ({ scheduleException }: DeletScheduleExceptionContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeleteScheduleException, isLoading } = useMutation(deleteScheduleException, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.SCHEDULES_EXCEPTIONS]);
      toast.dark(formatMessage({ id: AppMessages['schedule.exceptions.delete.success'] }));
      onModalClose();
    },
  });

  const onSubmit = () => {
    onDeleteScheduleException(scheduleException.id);
  };

  return (
    <DeleteScheduleException
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
