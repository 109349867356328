import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';
import { deleteSchedulePeriod } from 'api/schedules/schedules';

import { DeletePeriod } from './DeletePeriod';
import { DeletePeriodContainerProps } from './DeletePeriod.types';

export const DeletePeriodContainer = ({ selectedPeriod }: DeletePeriodContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();

  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeletePeriod, isLoading } = useMutation(deleteSchedulePeriod, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.SCHEDULE_DETAILS]);
      toast.dark(formatMessage({ id: AppMessages['schedule.details.delete.successMessage'] }));
      onModalClose();
    },
  });

  const onSubmit = () => {
    selectedPeriod.id && onDeletePeriod(selectedPeriod.id);
  };

  return (
    <DeletePeriod
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
