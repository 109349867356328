"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonsWrapper = exports.Wrapper = exports.Popover = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const styled_components_1 = __importStar(require("styled-components"));
exports.Popover = (0, styled_components_1.default)(({ className, ...props }) => ((0, jsx_runtime_1.jsx)(material_1.Popover, { ...props, classes: { root: className }, PaperProps: {
        className: 'paper',
    } })))(({ theme: { palette } }) => (0, styled_components_1.css) `
    & .paper {
      background-color: ${palette.common.white};
      border-radius: 8px;
      padding: 40px 0px;
      border: none;
      box-shadow: none;

      & > div > div {
        background-color: ${palette.common.white};
      }
    }
  `);
exports.Wrapper = styled_components_1.default.div(({ $withoutTimeInput, theme: { palette } }) => (0, styled_components_1.css) `
    & .MuiCalendarPicker-root {
      background-color: ${palette.common.white};
      display: flex;
      justify-content: center;
      width: 100%;
      font-size: 14px;
      border: none;
    }

    & .MuiTypography-caption {
      color: rgba(0, 0, 0, 0.38);
      font-size: 14px;
    }

    & .MuiIconButton-edgeEnd.Mui-disabled {
      visibility: hidden;
    }

    & .fTgjdr {
      background-color: ${palette.common.white};
    }

    & .css-15fas9l {
      border: none;
      background-color: inherit;
    }

    & .css-8v7him {
      position: relative;
    }

    & .css-y1b4va {
      font-weight: 700;
      text-transform: capitalize;
      position: absolute;
      left: calc(50% + 10px);
      transform: translateX(-50%);
    }

    & .PrivatePickersSlideTransition-root {
      min-height: ${$withoutTimeInput ? '200px' : '240px'};
    }

    & .css-1lomthf {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    & .MuiCalendarOrClockPicker-root {
      background-color: ${palette.common.white};
    }

    & .MuiPickersDay-root.Mui-disabled {
      color: ${palette.grey[300]} !important;
    }
  `);
exports.ButtonsWrapper = styled_components_1.default.div `
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  gap: 25px;
  padding: 0 30px;
`;
