import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';
import { deleteSchedule } from 'api/schedules/schedules';

import { DeleteSchedule } from './DeleteSchedule';
import { DeletScheduleContainerProps } from './DeleteSchedule.types';

export const DeleteScheduleContainer = ({ schedule }: DeletScheduleContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeleteSchedule, isLoading } = useMutation(deleteSchedule, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.SCHEDULES]);
      queryClient.invalidateQueries([QueryKeyEnum.SCHEDULES_SEARCH]);
      toast.dark(formatMessage({ id: AppMessages['schedule.delete.success'] }));
      onModalClose();
    },
  });

  const onSubmit = () => {
    onDeleteSchedule(schedule.id);
  };

  return (
    <DeleteSchedule
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
      schedule={schedule}
    />
  );
};
