import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

export const PeriodsWrapper = styled.div(
  ({ theme: { palette, shape } }) => css`
    border: 1px solid ${palette.grey[500]};
    padding: 14px;
    border-radius: ${shape.borderRadius}px;
  `,
);

export const TimeWrapper = styled.div`
  display: flex;
  font-weight: 700;
  margin-bottom: 16px;
  align-items: center;
  gap: 10px;
`;

export const PeriodField = styled(Typography)<{ $small?: boolean }>(
  ({ $small, theme: { palette, shape } }) => css`
    background-color: ${palette.grey['A400']};
    border-radius: ${shape.borderRadius}px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    min-height: 48px;
    min-width: ${$small ? 100 : 200}px;
    font-weight: 700;
  `,
);
