import { useLocale } from 'shared-ui';

import { DaysOfWeekEnum, DaysOfWeekMessagesEnum } from 'app/schedules/scheduleDetails/ScheduleDetails.enum';

import { DaysSelectorProps } from './DaysSelector.types';
import * as Styled from './DaysSelector.styles';

export const DaysSelector = ({ selectedDays, onChange, disabled }: DaysSelectorProps) => {
  const { formatMessage } = useLocale();

  return (
    <Styled.DaysWrapper>
      {Object.values(DaysOfWeekEnum).map((day) => (
        <Styled.DayButton
          value={day}
          selected={selectedDays.includes(day)}
          color="primary"
          onChange={() => onChange(day)}
          disabled={disabled ? disabled(day) : undefined}
        >
          {formatMessage({ id: DaysOfWeekMessagesEnum[day] })[0]}
        </Styled.DayButton>
      ))}
    </Styled.DaysWrapper>
  );
};
