import styled, { css } from 'styled-components';
import { ClockPicker as MaterialClockPicker } from '@mui/x-date-pickers/ClockPicker';
import { Input as InputBase } from 'shared-ui';

export const INPUT_WIDTH = 100;

export const Input = styled(InputBase)<{ $isError: boolean }>(
  ({ $isError, disabled, theme: { palette } }) => css`
    width: ${INPUT_WIDTH}px;

    & .MuiInputBase-root {
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      opacity: ${disabled ? 0.3 : 1};
    }

    & .MuiInputBase-input {
      padding: 14px 0px 12px 12px;
    }

    & ::placeholder {
      font-weight: 400;
      color: ${$isError ? palette.error.main : palette.common.black} !important;
      opacity: 1;
    }
  `,
);

export const ClockPicker = styled(MaterialClockPicker)`
  margin: 0px;
  width: 280px;
  padding-bottom: 24px;

  & .MuiClock-clock {
    background-color: #f2f3fa;
  }

  & .Mui-disabled {
    opacity: 0;
  }
`;

export const TimeButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  font-size: 24px;
  font-weight: 400;
  padding-top: 30px;
`;

export const TimeButton = styled.div<{ $isActive: boolean }>(
  ({ theme: { shape }, $isActive }) => css`
    width: 40px;
    height: 40px;
    background-color: ${$isActive ? '#B9D1F3' : '#f2f3fa'};
    border-radius: ${shape.borderRadius}px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `,
);
