import { useState } from 'react';

import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';

export const useSchedulesHistorySearchQuery = () => {
  const {
    storedQueries: { schedulesHistory: schedulesHistoryQuery },
  } = useLocaleStoreQuery();

  const [tkdSearchQuery, setTkdSearchQuery] = useState(schedulesHistoryQuery?.tkd?.label || '');
  const [executorSearchQuery, setExecutorSearchQuery] = useState(schedulesHistoryQuery?.query?.label || '');
  const [scheduleSearchQuery, setScheduleSearchQuery] = useState(schedulesHistoryQuery?.nazwa?.label || '');

  const clearSearchQueries = () => {
    setTkdSearchQuery('');
    setExecutorSearchQuery('');
    setScheduleSearchQuery('');
  };

  return {
    tkdSearchQuery,
    setTkdSearchQuery,
    executorSearchQuery,
    setExecutorSearchQuery,
    scheduleSearchQuery,
    setScheduleSearchQuery,
    clearSearchQueries,
  };
};
