import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { APIError, ClientError, useErrorHandler, useQueryClient } from 'shared-ui';

import { useModalState } from 'hooks/useModalState/useModalState';
import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyEnum } from 'core/global.enum';
import { fetchServers } from 'api/servers/servers';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { addSchedule } from 'api/schedules/schedules';
import { AddScheduleBody } from 'api/schedules/schedules.types';
import { checkAvailableTerminalsForSchedule, fetchAvailableTerminalsForSchedule } from 'api/terminals/terminals';
import { GroupTerminalsPermissionsIdSortEnum } from 'context/query/queryContext/QueryContext.enum';
import { ScheduleTypeEnum } from 'api/schedules/schedules.enum';

import { AddSchedule } from './AddSchedule';
import { AddScheduleForm } from './AddSchedule.types';
import { useAddScheduleValidation } from './AddSchedule.validation';

export const AddScheduleContainer = () => {
  const { isModalOpen, onModalClose, onModalOpen } = useModalState();
  const { schema } = useAddScheduleValidation();
  const { formatMessage } = useLocale();
  const queryClient = useQueryClient();
  const { handleError } = useErrorHandler();

  const formMethods = useForm<AddScheduleForm>({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { data: serversResponse } = useQuery([QueryKeyEnum.SERVERS], fetchServers);
  const servers = serversResponse?.data || [];
  const serwerId = formMethods.watch('serwerId');
  const typHarmonogramu = formMethods.watch('typ');

  useQuery([QueryKeyEnum.TERMINALS, serwerId], () => checkAvailableTerminalsForSchedule(serwerId), {
    enabled: isModalOpen,
    onSuccess: (response) => {
      if (response.isCreateHarmonogramNotAvailable) {
        formMethods.setError('tkds', {
          message: formatMessage({ id: AppMessages['schedule.add.validation.reader.defined'] }),
        });
      }
    },
  });

  const { data: terminalsResponse, isInitialLoading: isLoadingTerminals } = useQuery(
    [QueryKeyEnum.TERMINALS, serwerId, typHarmonogramu],
    () => fetchAvailableTerminalsForSchedule(serwerId),
    {
      args: {
        sort: [GroupTerminalsPermissionsIdSortEnum.ASC],
        zaakceptowane: true,
        wyswietlacz: true,
        typHarmonogramu,
        ...(typHarmonogramu === ScheduleTypeEnum.WEJSCIE_WYJSCIE ? { typTkd: 'AS' } : {}),
      },
      enabled: !!serwerId && !!typHarmonogramu,
      onSuccess: (response) => {
        if (!response.data.length) {
          return formMethods.setError('tkds', {
            message: formatMessage({ id: AppMessages['schedule.add.validation.reader.defined'] }),
          });
        }
        formMethods.clearErrors('tkds');
      },
    },
  );
  const terminals = terminalsResponse?.data || [];

  const { mutate: onAddSchedule, isLoading: isLoadingAddSchedule } = useMutation(addSchedule, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.SCHEDULES, serwerId]);
      queryClient.invalidateQueries([QueryKeyEnum.SCHEDULES_SEARCH, serwerId]);
      toast.dark(formatMessage({ id: AppMessages['schedule.add.successMessage'] }));
      onModalClose();
    },
    onError: (error: ClientError<APIError>) => {
      const errorFields = error.response?.data.fields;

      const isScheduleExistsError = !!errorFields?.find(({ type, field }) => type === 'exists' && field === 'nazwa');

      if (isScheduleExistsError) {
        return formMethods.setError('nazwa', {
          message: formatMessage({ id: AppMessages['schedule.add.validation.name.exists'] }),
        });
      }

      handleError({ error, omitAPIErrorMessage: true });
    },
  });

  const onSubmit = ({ nazwa, tkds, typ }: AddScheduleForm) => {
    onAddSchedule({ nazwa, tkds: tkds?.map((option) => option.value), typ } as unknown as AddScheduleBody);
  };

  useEffect(() => {
    servers.length && formMethods.reset({ serwerId: servers[0].id });
  }, [servers]);

  useEffect(() => {
    formMethods.reset();
  }, [isModalOpen]);

  return (
    <FormProvider {...formMethods}>
      <AddSchedule
        servers={servers}
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
        onSubmit={onSubmit}
        isLoadingAddSchedule={isLoadingAddSchedule}
        isLoadingTerminals={isLoadingTerminals}
        terminals={terminals}
      />
    </FormProvider>
  );
};
