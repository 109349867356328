import { FilterOptionsState } from '@mui/material';
import { Option } from 'shared-ui';

import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { SchedulesNameSortEnum } from 'context/query/queryContext/QueryContext.enum';
import * as Styled from '../TableSearch.styles';
import { fetchSchedules } from 'api/schedules/schedules';

export const useSearchByServerSchedules = (optionsQuery: string, isEnabled: boolean, serverId?: string) => {
  const { data: schedulesResponse, isInitialLoading } = useQuery(
    [QueryKeyEnum.SCHEDULES_SEARCH, serverId, optionsQuery],
    () => fetchSchedules(serverId),
    {
      args: {
        sort: [SchedulesNameSortEnum.ASC],
        query: optionsQuery,
      },
      enabled: isEnabled && !!serverId,
    },
  );

  const schedules = schedulesResponse?.data && serverId ? schedulesResponse?.data : [];

  const createOptions = () => {
    return schedules.map((schedule) => ({
      value: schedule.nazwa,
      label: schedule.nazwa,
    }));
  };

  const filterOptions = (options: Option[], { inputValue }: FilterOptionsState<Option>) => {
    const standarizedInputValue = inputValue.toLowerCase();

    return options.filter(
      ({ label, value }) =>
        label.toLowerCase().includes(standarizedInputValue) || `${value}`.toLowerCase().includes(standarizedInputValue),
    );
  };

  const createOptionLabel = (optionProps: React.HTMLAttributes<HTMLLIElement>, option: Option) => {
    return (
      <li {...optionProps}>
        <Styled.OptionLabel sx={{ py: 0.7 }}>{option.label}</Styled.OptionLabel>
      </li>
    );
  };

  return { isLoading: isInitialLoading, createOptions, createOptionLabel, filterOptions };
};
