import { useEffect } from 'react';
import { usePagination } from 'shared-ui';

import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyEnum } from 'core/global.enum';
import { QueryKeyLocalStorageEnum, SchedulesHistoryQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { fetchServers } from 'api/servers/servers';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { fetchSchedulesHistory } from 'api/schedules/schedules';

import { useSchedulesHistorySearchQuery } from './hooks/useSchedulesHistorySearchQuery';
import { SchedulesHistory } from './SchedulesHistory';

export const SchedulesHistoryContainer = () => {
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination();
  const {
    storedQueries: { schedulesHistory: schedulesHistoryQuery },
    setQuery,
  } = useLocaleStoreQuery();
  const {
    tkdSearchQuery,
    executorSearchQuery,
    scheduleSearchQuery,
    setExecutorSearchQuery,
    setScheduleSearchQuery,
    setTkdSearchQuery,
    clearSearchQueries,
  } = useSchedulesHistorySearchQuery();

  const { data: serversResponse } = useQuery([QueryKeyEnum.SERVERS], fetchServers);

  const { data: schedulesHistoryResponse, isLoading } = useQuery(
    [
      QueryKeyEnum.SCHEDULES_HISTORY,
      schedulesHistoryQuery,
      rowsPerPage,
      page,
      tkdSearchQuery,
      executorSearchQuery,
      scheduleSearchQuery,
    ],
    () => fetchSchedulesHistory(),
    {
      args: {
        ...schedulesHistoryQuery,
        [SchedulesHistoryQueryKeysEnum.SERWER]: schedulesHistoryQuery.serwer?.value,
        [SchedulesHistoryQueryKeysEnum.CZYTNIK]: schedulesHistoryQuery.tkd?.value,
        [SchedulesHistoryQueryKeysEnum.QUERY]: schedulesHistoryQuery.query?.value,
        [SchedulesHistoryQueryKeysEnum.NAZWA]: schedulesHistoryQuery.nazwa?.value,
        [SchedulesHistoryQueryKeysEnum.LIMIT]: rowsPerPage,
        [SchedulesHistoryQueryKeysEnum.OFFSET]: rowsPerPage * page,
      },
    },
  );

  useEffect(() => {
    setPage(0);
  }, [tkdSearchQuery, executorSearchQuery, schedulesHistoryQuery]);

  const onClearControlHistoryQuery = () => {
    setQuery(QueryKeyLocalStorageEnum.SCHEDULES_HISTORY, {});
    clearSearchQueries();
  };

  return (
    <SchedulesHistory
      schedulesHistoryResponse={schedulesHistoryResponse}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      servers={serversResponse?.data || []}
      tkdSearchQuery={tkdSearchQuery}
      executorSearchQuery={executorSearchQuery}
      scheduleSearchQuery={scheduleSearchQuery}
      setExecutorSearchQuery={setExecutorSearchQuery}
      setScheduleSearchQuery={setScheduleSearchQuery}
      setTkdSearchQuery={setTkdSearchQuery}
      onClearControlHistoryQuery={onClearControlHistoryQuery}
    />
  );
};
