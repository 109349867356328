import * as yup from 'yup';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { getStartOfToday } from 'helpers/getStartOfToday';

export const useAddExceptionValidation = () => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    data: yup
      .date()
      .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }))
      .min(getStartOfToday(), formatMessage({ id: AppMessages['common.validation.date.past'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
    typ: yup
      .string()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .required(formatMessage({ id: AppMessages['common.validation.required'] }))
      .typeError(formatMessage({ id: AppMessages['common.validation.required'] })),
    serwerId: yup.string().required(formatMessage({ id: AppMessages['common.validation.required'] })),
    tkds: yup
      .array()
      .min(1, formatMessage({ id: AppMessages['common.validation.required'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] }))
      .typeError(formatMessage({ id: AppMessages['common.validation.required'] })),
  });

  return { schema };
};
