import { useEffect, useState } from 'react';
import { usePagination } from 'shared-ui';

import { QueryKeyEnum } from 'core/global.enum';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import { fetchServers } from 'api/servers/servers';

import { SchedulesList } from './SchedulesList';
import { SchedulesListContainerProps } from './SchedulesList.types';

export const SchedulesListContainer = ({ schedulesTableSearchQuery }: SchedulesListContainerProps) => {
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.SCHEDULES]: schedulesQuery },
    setQuery,
  } = useLocaleStoreQuery();

  const [schedulesCount, setSchedulesCount] = useState(0);
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination();

  const { data: serversResponse, isLoading } = useQuery([QueryKeyEnum.SERVERS], fetchServers);

  useEffect(() => {
    setQuery(QueryKeyLocalStorageEnum.SCHEDULES, {
      ...schedulesQuery,
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    });
  }, [page, rowsPerPage]);

  return (
    <SchedulesList
      servers={serversResponse?.data}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      schedulesCount={schedulesCount}
      setSchedulesCount={setSchedulesCount}
      schedulesTableSearchQuery={schedulesTableSearchQuery}
    />
  );
};
