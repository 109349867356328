import { Table } from 'ui/table/Table';
import { StatusTableItem } from 'ui/statusTableItem/StatusTableItem';

import { UsersTableRowProps } from './UsersTableRow.types';

export const UsersTableRow = ({ user }: UsersTableRowProps) => {
  return (
    <>
      <Table.RowItem>{user.username}</Table.RowItem>

      <Table.RowItem>{`${user.nazwisko} ${user.imie}`}</Table.RowItem>

      <Table.RowTagItem tag={user.typ} />

      <Table.RowItem>
        <StatusTableItem status={user.status} smallFontSize />
      </Table.RowItem>
    </>
  );
};
