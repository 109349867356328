import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  APIError,
  ClientError,
  useErrorHandler,
  useLocale,
  useModalState,
  useMutation,
  useQueryClient,
} from 'shared-ui';
import { toast } from 'react-toastify';
import { endOfDay, format, getHours, getMinutes, parse } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';

import { DateFormat, QueryKeyEnum } from 'core/global.enum';
import { addSchedulePeriod } from 'api/schedules/schedules';
import { AppMessages } from 'i18n/messages';

import { DailyScheduleFormBody, DailyScheduleFormContainerProps } from './DailyScheduleForm.types';
import { DailyScheduleForm } from './DailyScheduleForm';
import { AddPeriodError } from './addPeriodError/AddPeriodError';
import { useDailyScheduleFormValidation } from './DailyScheduleForm.validation';

export const DailyScheduleFormContainer = ({
  period,
  rowNumber,
  scheduleType,
  currentDay,
  scheduleDetails,
  schedulePeriodsWithUnsaved,
  setSchedulePeriodsWithUnsaved,
}: DailyScheduleFormContainerProps) => {
  const { formatMessage } = useLocale();
  const { handleError } = useErrorHandler();
  const queryClient = useQueryClient();

  const { onModalOpen, onModalClose, isModalOpen } = useModalState();

  const startTime = period.startTime ? parse(period.startTime, 'HH:mm', new Date()) : null;
  const endTime = period.endTime ? parse(period.endTime, 'HH:mm', new Date()) : null;

  const { schema } = useDailyScheduleFormValidation();

  const formMethods = useForm<DailyScheduleFormBody>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      startTime,
      endTime,
      metodaWeryfikacji: undefined,
      dni: [currentDay],
    },
  });

  const { mutate: onAddSchedulePeriod, isLoading: isLoadingAddingSchedulePeriod } = useMutation(addSchedulePeriod, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.SCHEDULE_DETAILS]);
      toast.dark(formatMessage({ id: AppMessages['schedule.details.addSuccessMessage'] }));
    },
    onError: (error: ClientError<APIError<{ type: 'hasConflictWithOtherPeriods' }>>) => {
      const hasConflictWithOtherPeriods = error.response?.data.type === 'hasConflictWithOtherPeriods';

      if (hasConflictWithOtherPeriods) {
        onModalOpen();
        return;
      }

      handleError({ error, omitAPIErrorMessage: true });
    },
  });

  const onSubmit = (body: DailyScheduleFormBody) => {
    if (body.startTime && body.endTime) {
      onAddSchedulePeriod({
        id: scheduleDetails.id,
        body: {
          ...body,
          startTime: format(body.startTime, DateFormat.TIME_SHORT),
          endTime:
            getHours(body.endTime) === 0 && getMinutes(body.endTime) === 0
              ? format(endOfDay(body.endTime), DateFormat.TIME_SHORT)
              : format(body.endTime, DateFormat.TIME_SHORT),
        },
      });
    }
  };

  useEffect(() => {
    formMethods.reset({
      startTime,
      endTime,
      metodaWeryfikacji: period.typ,
      dni: [currentDay],
    });
  }, [schedulePeriodsWithUnsaved]);

  return (
    <FormProvider {...formMethods}>
      <AddPeriodError isModalOpen={isModalOpen} onModalClose={onModalClose} />

      <DailyScheduleForm
        onSubmit={onSubmit}
        rowNumber={rowNumber}
        scheduleType={scheduleType}
        scheduleDetails={scheduleDetails}
        currentDay={currentDay}
        schedulePeriodsWithUnsaved={schedulePeriodsWithUnsaved}
        setSchedulePeriodsWithUnsaved={setSchedulePeriodsWithUnsaved}
        isLoadingAddingSchedulePeriod={isLoadingAddingSchedulePeriod}
        period={period}
      />
    </FormProvider>
  );
};
