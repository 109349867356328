import { PageLayout } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';

import { ExceptionTerminalsProps } from './ExceptionTerminals.types';
import { ExceptionTerminalsTableHeader } from './exceptionTerminalsTableHeader/ExceptionTerminalsTableHeader';
import { ExceptionTerminalsTableRow } from './exceptionTerminalsTableRow/ExceptionTerminalsTableRow';

export const ExceptionTerminals = ({
  terminalsResponse,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}: ExceptionTerminalsProps) => {
  const { formatMessage } = useLocale();

  return (
    <PageLayout
      header={`${formatMessage({ id: AppMessages['schedules.exceptions.terminals.title'] })}`}
      withBackButton
      renderContent={() => (
        <>
          <Table
            isLoading={isLoading}
            pagination={{
              page,
              setPage,
              count: terminalsResponse?.count || 0,
              rowsPerPage,
              setRowsPerPage,
            }}
            gridTemplateColumns="1fr 5fr"
            data={terminalsResponse?.data || []}
            nothingToShowText={formatMessage({ id: AppMessages['schedules.exceptions.terminals.nothingToShow'] })}
            renderHeader={() => <ExceptionTerminalsTableHeader />}
            renderRow={(terminal) => <ExceptionTerminalsTableRow terminal={terminal} />}
          />
        </>
      )}
    />
  );
};
