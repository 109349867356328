import { useScheduleTypeValues } from 'app/schedules/hooks/useScheduleTypeValues';

import { generateTimeSlots } from './utils/generateTimeSlots';
import { TimeRangeProps } from './TimeRange.types';
import * as Styled from './TimeRange.styles';

export const TimeRange = ({ schedulePeriod, timeSlot, scheduleType }: TimeRangeProps) => {
  const timeSlots = generateTimeSlots(timeSlot);

  const numberOfSlots = timeSlots.length - 1;

  const { scheduleTypeValues } = useScheduleTypeValues();

  return (
    <Styled.TimeRangeWrapper numberOfSlots={numberOfSlots}>
      {timeSlots.map((timeSlot, index) => {
        const [hour, minutes] = timeSlot.split(':');

        const fullHour = minutes === '00' ? hour.replace(/^0/, '') : undefined;

        const isLastSlot = numberOfSlots === index;
        const isFirstSlot = index === 0;

        return (
          <Styled.HourWrapper id={timeSlot} key={timeSlot}>
            <Styled.FullTimeNumber $isLastSlot={isLastSlot}>{fullHour}</Styled.FullTimeNumber>

            <Styled.TimelineWrapper>
              {!isLastSlot && <Styled.TopBorder isFirstSlot={isFirstSlot} />}

              <Styled.Timeline isFullHour={!!fullHour} isFirstSlot={isFirstSlot} isLastSlot={isLastSlot} />
            </Styled.TimelineWrapper>
          </Styled.HourWrapper>
        );
      })}

      {schedulePeriod.map((period) => {
        const startIndex = timeSlots.indexOf(period.startTime || '00:00');
        const endIndex = timeSlots.indexOf(period.endTime || '00:00');

        const backgroundColor = period && period.typ ? scheduleTypeValues[scheduleType][period.typ]?.color : undefined;

        return (
          <Styled.Tooltip title={`${period.startTime} - ${period.endTime}`} placement="top" arrow key={period.id}>
            <Styled.Overlay
              $startIndex={startIndex}
              $endIndex={endIndex}
              $numberOfSlots={numberOfSlots}
              $backgroundColor={backgroundColor || ''}
            />
          </Styled.Tooltip>
        );
      })}
    </Styled.TimeRangeWrapper>
  );
};
