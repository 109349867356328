import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Modal, useModalState, DayPickerIcon } from 'shared-ui';
import { Typography } from '@mui/material';

import { ReactComponent as ClosePrimaryIcon } from 'assets/icons/close-primary-icon.svg';
import { ReactComponent as InfoPrimaryIcon } from 'assets/icons/info-primary.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AddSchedulePeriodBody } from 'api/schedules/schedules.types';
import { DaysOfWeekEnum } from 'app/schedules/scheduleDetails/ScheduleDetails.enum';
import { MAX_SCHEDULE_PERIODS } from 'app/schedules/scheduleDetails/ScheduleDetails';
import { DaysSelector } from 'ui/daysSelector/DaysSelector';
import * as RootStyled from '../DailyScheduleForm.styles';
import { getFullyDefinedDays } from 'app/schedules/utils/getFullyDefinedDays';

import { PeriodSettingsProps } from './PeriodSettings.types';
import * as Styled from './PeriodSettings.styles';

export const PeriodSettings = ({ scheduleDetails, currentDay }: PeriodSettingsProps) => {
  const { formatMessage } = useLocale();
  const { isModalOpen, onModalClose, onModalOpen } = useModalState();

  const [unsavedSelectedDays, setUnsavedSelectedDays] = useState<DaysOfWeekEnum[]>([currentDay]);

  const { watch, setValue } = useFormContext<AddSchedulePeriodBody>();

  const { dni } = watch();

  const fullyDefinedDays = getFullyDefinedDays(scheduleDetails.dni);

  const daysWithMaxPeriods = Object.entries(scheduleDetails.dni)
    .filter(
      ([day, periods]) => periods.length === MAX_SCHEDULE_PERIODS || fullyDefinedDays.includes(day as DaysOfWeekEnum),
    )
    .map(([key]) => key);

  const limitedSelectedDaysByMaxPeriods = Object.values(DaysOfWeekEnum).filter(
    (currentDay) => !daysWithMaxPeriods.includes(currentDay),
  );

  const onDayButtonClick = (day: DaysOfWeekEnum) => {
    setUnsavedSelectedDays(
      unsavedSelectedDays.includes(day)
        ? unsavedSelectedDays.filter((currentDay) => currentDay !== day)
        : [...unsavedSelectedDays, day],
    );
  };

  const areAllAvailableDaysSelected = unsavedSelectedDays.length === limitedSelectedDaysByMaxPeriods.length;

  const onToggleAllDaysButtonClick = () => {
    if (areAllAvailableDaysSelected) {
      setUnsavedSelectedDays([currentDay]);
    } else {
      setUnsavedSelectedDays(limitedSelectedDaysByMaxPeriods);
    }
  };

  const areAllDaysDisabledByPeriodsLimit = daysWithMaxPeriods.length === Object.keys(DaysOfWeekEnum).length - 1;

  return (
    <>
      <RootStyled.SmallButton variant="outlined" onClick={onModalOpen}>
        <DayPickerIcon />
      </RootStyled.SmallButton>

      <Modal
        header={formatMessage({ id: AppMessages['schedule.details.scheduleSettings.title'] })}
        onClose={() => {
          setUnsavedSelectedDays(dni);
          onModalClose();
        }}
        open={isModalOpen}
        type="form"
        onSubmit={() => {
          setValue('dni', unsavedSelectedDays);
          onModalClose();
        }}
        submitButtonText={
          areAllDaysDisabledByPeriodsLimit
            ? formatMessage({ id: AppMessages['common.ok'] })
            : formatMessage({ id: AppMessages['common.save'] })
        }
      >
        <>
          <Typography variant="subtitle1" sx={{ paddingBottom: 3 }}>
            {formatMessage({ id: AppMessages['schedule.details.scheduleSettings.text'] }, { newLine: <br /> })}
          </Typography>

          <Typography sx={{ paddingBottom: 3, fontWeight: 700 }}>
            {formatMessage({ id: AppMessages['schedule.details.scheduleSettings.subtitle'] })}
          </Typography>

          <Styled.DaysMainWrapper>
            <DaysSelector
              selectedDays={unsavedSelectedDays}
              onChange={(day) => onDayButtonClick(day)}
              disabled={(day) => day === currentDay || daysWithMaxPeriods.includes(day)}
            />

            <Styled.ToggleAllDaysButton
              onClick={onToggleAllDaysButtonClick}
              variant="outlined"
              disabled={areAllDaysDisabledByPeriodsLimit}
            >
              {areAllAvailableDaysSelected && !areAllDaysDisabledByPeriodsLimit ? (
                <>
                  <ClosePrimaryIcon />
                  {formatMessage({
                    id: AppMessages['schedule.details.scheduleSettings.deselectDays'],
                  })}
                </>
              ) : (
                <>
                  <Styled.CheckPrimaryIcon $disabled={areAllDaysDisabledByPeriodsLimit} />
                  {formatMessage({
                    id: AppMessages['schedule.details.scheduleSettings.selectDays'],
                  })}
                </>
              )}
            </Styled.ToggleAllDaysButton>
          </Styled.DaysMainWrapper>

          {!!daysWithMaxPeriods.length && (
            <Styled.InfoContainer>
              <InfoPrimaryIcon />

              <Styled.InfoText>
                {formatMessage({
                  id: AppMessages[
                    daysWithMaxPeriods.length === Object.values(DaysOfWeekEnum).length - 1
                      ? 'schedule.details.scheduleSettings.remainingDaysDisabledInfo'
                      : 'schedule.details.scheduleSettings.someDaysDisabledInfo'
                  ],
                })}
              </Styled.InfoText>
            </Styled.InfoContainer>
          )}
        </>
      </Modal>
    </>
  );
};
