import { useEffect } from 'react';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useExpandedServerId } from 'hooks/useExpandedServerId/useExpandedServerId';
import { fetchSchedulesExceptions } from 'api/schedules/schedules';

import { SchedulesExceptionsTable } from './SchedulesExceptions';
import { SchedulesExceptionsTableContainerProps } from './SchedulesExceptions.types';

export const SchedulesExceptionsTableContainer = ({
  expandedServer,
  setSchedulesCount,
}: SchedulesExceptionsTableContainerProps) => {
  const serverId = expandedServer?.id || '';
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.SCHEDULES_EXCEPTIONS]: schedulesExceptionsQuery },
  } = useLocaleStoreQuery();
  const { setExpandedServerId } = useExpandedServerId();

  const { data: schedulesExceptionsResponse } = useQuery(
    [QueryKeyEnum.SCHEDULES_EXCEPTIONS, serverId, schedulesExceptionsQuery],
    () => fetchSchedulesExceptions(serverId),
    {
      args: { ...schedulesExceptionsQuery },
      enabled: !!expandedServer,
      onSuccess: (response) => {
        setSchedulesCount(response.count);
      },
    },
  );

  useEffect(() => {
    setExpandedServerId(serverId);
  }, [serverId]);

  return <SchedulesExceptionsTable schedulesExceptionsResponse={schedulesExceptionsResponse} />;
};
