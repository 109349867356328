import * as yup from 'yup';
import { addDays, getHours, getMinutes, isAfter, isBefore, isValid } from 'date-fns';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { TIME_SLOT } from '../DailySchedule';

export const useDailyScheduleFormValidation = () => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    metodaWeryfikacji: yup
      .string()
      .required(formatMessage({ id: AppMessages['schedule.details.add.error.fieldRequired'] })),

    startTime: yup
      .date()
      .nullable()
      .required(formatMessage({ id: AppMessages['schedule.details.add.error.fieldRequired'] }))
      .test('startTime-format', formatMessage({ id: AppMessages['common.timepicker.invalidFormat'] }), function () {
        // enlist-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const originalValue = this.originalValue;
        return isValid(originalValue) || getHours(originalValue) < 24 || getMinutes(originalValue) < 60;
      })
      .test(
        'startTime-15-minute-step',
        formatMessage({ id: AppMessages['schedule.details.add.error.minPeriod'] }),
        (value) => {
          if (!value) return true;
          const minutes = value.getMinutes();
          return minutes % TIME_SLOT === 0;
        },
      )
      .test(
        'startTime-before-endTime',
        formatMessage({ id: AppMessages['schedule.details.add.error.invalidTime'] }),
        function (value) {
          const { endTime } = this.parent;

          return value && endTime ? isBefore(value, endTime) : true;
        },
      )
      .test(
        'startTime-minimum-15-minutes',
        formatMessage({ id: AppMessages['schedule.details.add.error.minPeriod'] }),
        function (value) {
          const { endTime } = this.parent;

          if (value && endTime) {
            const start = value.getTime();
            const end = endTime.getTime();
            const differenceInMinutes = (end - start) / (1000 * 60);

            return differenceInMinutes >= TIME_SLOT;
          }

          return true;
        },
      )
      .transform((curr, orig) => {
        return orig === null ? null : orig && isValid(orig) ? curr : new Date();
      }),

    endTime: yup
      .date()
      .nullable()
      .required(formatMessage({ id: AppMessages['schedule.details.add.error.fieldRequired'] }))
      .transform((curr, orig) => {
        return getHours(orig) === 0 && getMinutes(orig) === 0 ? addDays(orig, 1) : curr;
      })
      .test('startTime-format', formatMessage({ id: AppMessages['common.timepicker.invalidFormat'] }), function () {
        // enlist-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const originalValue = this.originalValue;
        return isValid(originalValue) || getHours(originalValue) < 24 || getMinutes(originalValue) < 60;
      })
      .test(
        'endTime-15-minute-step',
        formatMessage({ id: AppMessages['schedule.details.add.error.minPeriod'] }),
        (value) => {
          if (!value) return true;
          const minutes = value.getMinutes();
          return minutes % TIME_SLOT === 0;
        },
      )
      .test(
        'endTime-after-startTime',
        formatMessage({ id: AppMessages['schedule.details.add.error.invalidTime'] }),
        function (value) {
          const { startTime } = this.parent;

          return value && startTime ? isAfter(value, startTime) : true;
        },
      )
      .transform((curr, orig) => {
        return orig === null ? null : orig && isValid(orig) ? curr : new Date();
      }),
  });

  return { schema };
};
