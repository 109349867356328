"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatepickerPopover = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const x_date_pickers_1 = require("@mui/x-date-pickers");
const material_1 = require("@mui/material");
const date_fns_1 = require("date-fns");
const LeftArrowDatepicker_1 = require("../../../assets/icons/LeftArrowDatepicker");
const RightArrowDatepicker_1 = require("../../../assets/icons/RightArrowDatepicker");
const useLocale_1 = require("../../../hooks/useLocale/useLocale");
const Button_1 = require("../../button/Button");
const global_enum_1 = require("../../../core/global.enum");
const messages_1 = require("../../../core/i18n/messages");
const CustomDay_1 = require("./customDay/CustomDay");
const CustomTimeInput_1 = require("./customTimeInput/CustomTimeInput");
const Styled = __importStar(require("./DatepickerPopover.styles"));
const DatepickerPopover = ({ toogleDatepicker, isOpen, datepickerEl, popoverOrigin, customSetDateFormat, customDateFormatOnOpen, withoutTimeInput, ...props }) => {
    const { formatMessage } = (0, useLocale_1.useLocale)();
    const [date, setDate] = (0, react_1.useState)(null);
    const minDate = (0, react_1.useMemo)(() => {
        return props.minDate;
    }, [props.minDate]);
    const isMinDateError = !!(minDate && date && date < minDate);
    (0, react_1.useEffect)(() => {
        if (customDateFormatOnOpen && isOpen) {
            const formattedDate = customDateFormatOnOpen();
            setDate(formattedDate);
        }
    }, [isOpen]);
    (0, react_1.useEffect)(() => {
        if (customDateFormatOnOpen) {
            return;
        }
        const formattedDate = props.value && new Date(props.value);
        if (formattedDate instanceof Date && (0, date_fns_1.isValid)(formattedDate))
            setDate(formattedDate);
    }, [props.value]);
    const handleDateSet = () => {
        props.onChange(date);
        toogleDatepicker();
    };
    const closeWithDateClear = () => {
        toogleDatepicker();
    };
    return ((0, jsx_runtime_1.jsx)(Styled.Popover, { id: 'datepicker', open: isOpen, anchorEl: datepickerEl, onClose: closeWithDateClear, anchorOrigin: popoverOrigin, disableScrollLock: true, children: (0, jsx_runtime_1.jsxs)(Styled.Wrapper, { "$withoutTimeInput": withoutTimeInput, children: [(0, jsx_runtime_1.jsx)(x_date_pickers_1.StaticDatePicker, { ...props, value: date, components: { RightArrowIcon: RightArrowDatepicker_1.RightArrowDatepicker, LeftArrowIcon: LeftArrowDatepicker_1.LeftArrowDatepicker }, disableHighlightToday: true, onChange: (date) => {
                        const validDateWithMinCheck = !minDate ? date : !date ? null : date < minDate ? new Date() : date;
                        if (customSetDateFormat) {
                            const formattedDate = customSetDateFormat(validDateWithMinCheck);
                            setDate(formattedDate);
                            return;
                        }
                        setDate(validDateWithMinCheck);
                    }, displayStaticWrapperAs: "desktop", openTo: "day", renderInput: (params) => (0, jsx_runtime_1.jsx)(material_1.TextField, { ...params }), views: ['day'], renderDay: (dayDate, _, props) => ((0, jsx_runtime_1.jsx)(CustomDay_1.CustomDay, { selected: Boolean(date && (0, date_fns_1.format)(date, global_enum_1.DateFormat.DATE) === (0, date_fns_1.format)(dayDate, global_enum_1.DateFormat.DATE)), ...props })) }), !withoutTimeInput && ((0, jsx_runtime_1.jsx)(CustomTimeInput_1.CustomTimeInput, { date: date, setDate: setDate, isMinDateError: isMinDateError }, date ? date.getDay() : undefined)), (0, jsx_runtime_1.jsxs)(Styled.ButtonsWrapper, { children: [(0, jsx_runtime_1.jsx)(Button_1.Button, { color: "primary", size: "small", onClick: closeWithDateClear, children: formatMessage({ id: messages_1.AppMessages['datepicker.popper.cancel'] }) }), (0, jsx_runtime_1.jsx)(Button_1.Button, { onClick: handleDateSet, disabled: !date || isMinDateError, size: "small", variant: "contained", color: "primary", type: "submit", children: formatMessage({ id: messages_1.AppMessages['datepicker.popper.confirm'] }) })] })] }) }));
};
exports.DatepickerPopover = DatepickerPopover;
