import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

import { ScheduleTypeProps } from './ScheduleType.types';

const typeBackgroundColor = (type: ScheduleTypeProps['type']) => {
  return {
    METODY_WERYFIKACJI: '#65B5FF',
    PRZEJSCIE: '#FFBD61',
    WEJSCIE_WYJSCIE: '#D8C8FC',
    ODBLOKOWANE: '#BB2D30',
    WARUNKOWO_ODBLOKOWANE: '#F9E29C',
    ZABLOKOWANE: '#E27A25',
  }[type];
};

const typeTextColor = (type: ScheduleTypeProps['type']) => {
  return {
    METODY_WERYFIKACJI: '#383838',
    PRZEJSCIE: '#383838',
    WEJSCIE_WYJSCIE: '#383838',
    ODBLOKOWANE: '#FFFFFF',
    WARUNKOWO_ODBLOKOWANE: '#000000',
    ZABLOKOWANE: '#FFFFFF',
  }[type];
};

export const Container = styled.div<ScheduleTypeProps>(
  ({ type }) => css`
    background-color: ${typeBackgroundColor(type)};
    height: 32px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 6px 16px;
  `,
);

export const Text = styled(Typography)<ScheduleTypeProps>(
  ({ type }) => css`
    color: ${typeTextColor(type)};
    font-size: 14px;
    font-weight: 600;
    font-weight: 600 !important;
    line-height: 1;
  `,
);
