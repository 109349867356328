import { useEffect, useRef, useState } from 'react';
import { IconButton, List, Collapse, Typography } from '@mui/material';
import { format } from 'date-fns';
import { NothingToShow, Pagination, TOP_BAR_HEIGHT, usePageLayoutStyles } from 'shared-ui';
import { useMeasure, useWindowSize } from 'react-use';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right-card-history.svg';
import { DateFormat, QueryKeyEnum } from 'core/global.enum';
import { Loader } from 'ui/loader/Loader';
import { StatusTableItem } from 'ui/statusTableItem/StatusTableItem';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { fetchOwnerHistoryNewPhoto, fetchOwnerHistoryOldPhoto } from 'api/owners/owners';
import { useQuery } from 'hooks/useQuery/useQuery';
import { Table } from 'ui/table/Table';

import {
  ActionBodyItemProps,
  ActionModifiedItemProps,
  ActionModifiedFieldBodyItemProps,
  HistoryListProps,
  HistoryMandatoryDataFields,
  ActionPhotoModifiedItemProps,
  ActionListBodyItemProps,
  ActionExpandablePermissionsBodyItemProps,
  ActionExpandablePermissionEntryProps,
  RenderedActionListdBodyItemProps,
} from './HistoryList.types';
import * as Styled from './HistoryList.styles';

const { RowTagItem } = Table;

const ADDITIONAL_LIST_SPACES = 40;

export const HistoryList = <T extends HistoryMandatoryDataFields>({
  renderHistoryItemBody,
  data,
  isLoading,
  filters,
  pagination,
}: HistoryListProps<T>) => {
  const { formatMessage } = useLocale();

  const { height: windowHeight } = useWindowSize();
  const [filtersWrapperRef, { height: filtersHeight }] = useMeasure<HTMLDivElement>();
  const [paginationWrapperRef, { height: paginationHeight }] = useMeasure<HTMLDivElement>();
  const { headerHeight, paddingBottom, paddingTop } = usePageLayoutStyles();

  const calculatedHistoryListHeight =
    windowHeight -
    filtersHeight -
    paginationHeight -
    headerHeight -
    paddingBottom -
    paddingTop -
    TOP_BAR_HEIGHT -
    ADDITIONAL_LIST_SPACES;

  return (
    <>
      <Styled.FiltersWrapper ref={filtersWrapperRef}>
        <Styled.Filters $gridTemplateColumns={filters.gridTemplateColumns}>
          {filters.render()}

          {filters.isClearButtonVisible && (
            <Styled.ClearFiltersButtonWrapper onClick={filters.onClearFilters}>
              <Styled.ClearFiltersIcon />
              {formatMessage({ id: AppMessages['common.clear'] })}
            </Styled.ClearFiltersButtonWrapper>
          )}
        </Styled.Filters>
      </Styled.FiltersWrapper>

      <div ref={paginationWrapperRef}>
        <Pagination {...pagination} />
      </div>

      {!data.length ? (
        <NothingToShow text={formatMessage({ id: AppMessages['common.history.nothingToShow'] })} />
      ) : isLoading ? (
        <Loader />
      ) : (
        <OverlayScrollbarsComponent defer options={{ scrollbars: { autoHide: 'scroll' } }}>
          <List data-testid="history-action-items" style={{ height: calculatedHistoryListHeight }}>
            {data.map((history) => (
              <Styled.ListItem key={history.id} data-testid="history-action-item">
                <Styled.ListItemHeaderWrapper data-testid={`history-action-item-${history.akcja}`.toLocaleLowerCase()}>
                  <StatusTableItem status={history.akcja} textBold />

                  <Styled.BoldText>{format(new Date(history.updatedAt), DateFormat.FULL)}</Styled.BoldText>
                </Styled.ListItemHeaderWrapper>

                <Styled.ListItemBodyWrapper>{renderHistoryItemBody(history)}</Styled.ListItemBodyWrapper>
              </Styled.ListItem>
            ))}
          </List>
        </OverlayScrollbarsComponent>
      )}
    </>
  );
};

const ActionBodyItem = ({ title, value }: ActionBodyItemProps) => {
  return (
    <Styled.HistoryItemWrapper>
      <Styled.FieldTitle>{title}</Styled.FieldTitle>

      {typeof value === 'string' ? <Styled.TextItem data-testid="text-item">{value}</Styled.TextItem> : value}
    </Styled.HistoryItemWrapper>
  );
};

const ActionModifiedFieldBodyItem = ({ title, oldValue, newValue }: ActionModifiedFieldBodyItemProps) => {
  return (
    <Styled.HistoryItemWrapper>
      <Styled.FieldTitle>{title}</Styled.FieldTitle>

      <Styled.ModifiedItemWrapper data-testid="modified-field-item">
        <Styled.ModifiedOldItem>{oldValue && oldValue}</Styled.ModifiedOldItem>

        <Styled.ArrowRightWrapper>
          <ArrowRightIcon />
        </Styled.ArrowRightWrapper>

        <Styled.ModifiedItem>{newValue && newValue}</Styled.ModifiedItem>
      </Styled.ModifiedItemWrapper>
    </Styled.HistoryItemWrapper>
  );
};

const ActionModifiedLongFieldBodyItem = ({ title, oldValue, newValue }: ActionModifiedItemProps) => {
  return (
    <Styled.HistoryItemWrapper>
      <Styled.FieldTitle>{title}</Styled.FieldTitle>

      <Styled.ModifiedLongItemWrapper data-testid="long-modified-field-item">
        <Styled.ModifiedLongOldItem>{oldValue && oldValue}</Styled.ModifiedLongOldItem>

        <Styled.ModifiedLongNewItemWrapper>
          <Styled.ArrowRightIcon />

          <Styled.ModifiedLongItem>{newValue && newValue}</Styled.ModifiedLongItem>
        </Styled.ModifiedLongNewItemWrapper>
      </Styled.ModifiedLongItemWrapper>
    </Styled.HistoryItemWrapper>
  );
};

const ActionModifiedContainerPhotoBodyItem = ({
  historyElementId,
  title,
  oldValue,
  newValue,
}: ActionPhotoModifiedItemProps) => {
  const { data: oldPhoto } = useQuery(
    [QueryKeyEnum.OWNER_HISTORY_PHOTO_OLD, historyElementId],
    () => fetchOwnerHistoryOldPhoto(historyElementId),
    {
      enabled: !!oldValue,
    },
  );

  const { data: newPhoto } = useQuery(
    [QueryKeyEnum.OWNER_HISTORY_PHOTO_NEW, historyElementId],
    () => fetchOwnerHistoryNewPhoto(historyElementId),
    {
      enabled: !!newValue,
    },
  );

  return (
    <Styled.HistoryItemWrapper>
      <Styled.FieldTitle>{title}</Styled.FieldTitle>

      <Styled.ModifiedPhotoWrapper data-testid="modified-photo-item">
        <Styled.ModifiedPhotoItem $image={oldPhoto ? URL.createObjectURL(oldPhoto) : undefined} $faded={!!oldPhoto}>
          {!oldPhoto && <Styled.EmptyPictureIcon />}
        </Styled.ModifiedPhotoItem>

        <ArrowRightIcon />

        <Styled.ModifiedPhotoItem $image={newPhoto ? URL.createObjectURL(newPhoto) : undefined}>
          {!newPhoto && <Styled.EmptyPictureIcon />}
        </Styled.ModifiedPhotoItem>
      </Styled.ModifiedPhotoWrapper>
    </Styled.HistoryItemWrapper>
  );
};

const ActionModifiedPinBodyItem = ({ title, oldValue, newValue }: ActionModifiedItemProps) => {
  const dottsValue = () => (
    <>
      <Styled.PinDot></Styled.PinDot>
      <Styled.PinDot></Styled.PinDot>
      <Styled.PinDot></Styled.PinDot>
      <Styled.PinDot></Styled.PinDot>
    </>
  );

  return (
    <ActionModifiedFieldBodyItem
      title={title}
      oldValue={oldValue && dottsValue()}
      newValue={newValue && dottsValue()}
    />
  );
};

const ActionListdBodyItem = <T extends { id: string }>({
  title,
  items,
  renderItemBody,
  shouldScrollToTop,
}: ActionListBodyItemProps<T>) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const maxListHeight = 350;

  useEffect(() => {
    if (!shouldScrollToTop && ref.current?.clientHeight && ref.current?.clientHeight >= maxListHeight) return;

    setTimeout(() => {
      ref.current?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 100);
  }, [shouldScrollToTop]);

  return (
    <Styled.HistoryItemWrapper>
      <Styled.FieldTitle>{title}</Styled.FieldTitle>

      <Styled.ActionListContainer ref={ref} maxListHeight={maxListHeight}>
        <Styled.ModifiedLongItemWrapper>
          {items.map((item) => (
            <Styled.ModifiedLongItem>{renderItemBody(item)}</Styled.ModifiedLongItem>
          ))}
        </Styled.ModifiedLongItemWrapper>
      </Styled.ActionListContainer>
    </Styled.HistoryItemWrapper>
  );
};

const RenderedActionListdBodyItem = ({ numberPrefix, number, value }: RenderedActionListdBodyItemProps) => {
  return (
    <span>
      <Typography variant="body2">{`${numberPrefix} ${number}`}</Typography>
      <Typography style={{ fontWeight: 700 }}>{value}</Typography>
    </span>
  );
};

const DetailsLoader = () => {
  return (
    <Styled.HistoryListDetailsLoaderWrapper>
      <Loader />
    </Styled.HistoryListDetailsLoaderWrapper>
  );
};

const PermissionEntry = ({ title, value, id }: ActionExpandablePermissionEntryProps) => {
  return (
    <Styled.PermissionEntryContainer>
      <Styled.PermissionEntryTitle>{title}</Styled.PermissionEntryTitle>

      <Styled.PermissionEntryField data-testid={id}>
        <RowTagItem tag={value} />
      </Styled.PermissionEntryField>
    </Styled.PermissionEntryContainer>
  );
};

const ActionExpandablePermissionsBodyItem = ({
  title,
  data,
  shouldScrollToTop,
  action,
}: ActionExpandablePermissionsBodyItemProps) => {
  const { formatMessage } = useLocale();
  const ref = useRef<HTMLUListElement | null>(null);
  const maxListHeight = 800;

  useEffect(() => {
    if (!shouldScrollToTop && ref.current?.clientHeight && ref.current?.clientHeight >= maxListHeight) return;

    setTimeout(() => {
      ref.current?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 100);
  }, [shouldScrollToTop]);

  useEffect(() => {
    const updatedExpandableData = data.map((item) => {
      const updatedElement = expandableData.find((data) => data.id === item.id);

      return { ...item, isExpanded: updatedElement?.isExpanded || false };
    });
    setExpandableData(updatedExpandableData);
  }, [data]);

  const [expandableData, setExpandableData] = useState(data.map((item) => ({ ...item, isExpanded: false })));

  const onExpandItem = (id: string) => {
    setExpandableData((prevState) =>
      prevState.map((item) => (item.id === id ? { ...item, isExpanded: !item.isExpanded } : item)),
    );
  };

  return (
    <Styled.HistoryItemWrapper>
      <Styled.FieldTitle>{title}</Styled.FieldTitle>

      <Styled.ExpandablePermissionsList maxListHeight={maxListHeight} ref={ref}>
        {expandableData.map(({ id, numer, nazwa, uprawnienia, isExpanded }) => (
          <Styled.ExpandablePermissionsListItem key={id}>
            <Styled.ExpandableItemFieldWrapper data-testid={`${nazwa}-${action}`}>
              <Styled.ModifiedLongItem>
                <span>
                  <Typography variant="body2">
                    {formatMessage({ id: AppMessages['common.id'] })} {numer}
                  </Typography>
                  <Typography style={{ fontWeight: 700 }}>{nazwa}</Typography>
                </span>
              </Styled.ModifiedLongItem>

              <Styled.ExpandableArrowWrapper>
                {uprawnienia && (
                  <IconButton onClick={() => onExpandItem(id)} size="large">
                    {isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
                  </IconButton>
                )}
              </Styled.ExpandableArrowWrapper>
            </Styled.ExpandableItemFieldWrapper>

            {uprawnienia && (
              <Collapse in={isExpanded}>
                <Styled.ExpandableBody>
                  <PermissionEntry
                    id={`${nazwa}-${action}-odbicie`}
                    title={formatMessage({ id: AppMessages['owner.history.effectivePermissions.registerLock'] })}
                    value={uprawnienia.rejestrujOdbicie}
                  />

                  <PermissionEntry
                    id={`${nazwa}-${action}-zamek`}
                    title={formatMessage({ id: AppMessages['owner.history.effectivePermissions.openLock'] })}
                    value={uprawnienia.otwierajZamek}
                  />

                  <PermissionEntry
                    id={`${nazwa}-${action}-rcp`}
                    title={formatMessage({ id: AppMessages['owner.history.effectivePermissions.registerRcp'] })}
                    value={uprawnienia.rejestrujRcp}
                  />
                </Styled.ExpandableBody>
              </Collapse>
            )}
          </Styled.ExpandablePermissionsListItem>
        ))}
      </Styled.ExpandablePermissionsList>
    </Styled.HistoryItemWrapper>
  );
};

HistoryList.ActionBodyItem = ActionBodyItem;
HistoryList.ActionModifiedFieldBodyItem = ActionModifiedFieldBodyItem;
HistoryList.ActionModifiedLongFieldBodyItem = ActionModifiedLongFieldBodyItem;
HistoryList.ActionModifiedContainerPhotoBodyItem = ActionModifiedContainerPhotoBodyItem;
HistoryList.ActionModifiedPinBodyItem = ActionModifiedPinBodyItem;
HistoryList.ActionListdBodyItem = ActionListdBodyItem;
HistoryList.RenderedActionListdBodyItem = RenderedActionListdBodyItem;
HistoryList.DetailsLoader = DetailsLoader;
HistoryList.ActionExpandablePermissionsBodyItem = ActionExpandablePermissionsBodyItem;
