export enum ScheduleTypeEnum {
  METODY_WERYFIKACJI = 'METODY_WERYFIKACJI',
  PRZEJSCIE = 'PRZEJSCIE',
  WEJSCIE_WYJSCIE = 'WEJSCIE_WYJSCIE',
}

export enum ScheduleTypeDescriptionEnum {
  METODY_WERYFIKACJI = 'schedules.filters.type.verfication',
  PRZEJSCIE = 'schedules.filters.type.enter',
  WEJSCIE_WYJSCIE = 'schedules.filters.type.enterExit',
}

export enum ScheduleTypeSingularDescriptionEnum {
  METODY_WERYFIKACJI = 'schedules.type.verfication',
  PRZEJSCIE = 'schedules.type.enter',
  WEJSCIE_WYJSCIE = 'schedules.type.enterExit',
}

export enum ScheduleVerificationMethods {
  KARTA = 'KARTA',
  KARTA_PIN = 'KARTA_PIN',
}

export enum ScheduleInputOutput {
  WEJSCIE = 'WEJSCIE',
  WYJSCIE = 'WYJSCIE',
}

export enum ScheduleTransition {
  ODBLOKOWANE = 'ODBLOKOWANE',
  WARUNKOWO_ODBLOKOWANE = 'WARUNKOWO_ODBLOKOWANE',
  ZABLOKOWANE = 'ZABLOKOWANE',
}

export enum ScheduleTransitionDescriptionEnum {
  ODBLOKOWANE = 'schedules.transition.unlocked',
  WARUNKOWO_ODBLOKOWANE = 'schedules.transition.conditionallyUnlocked',
  ZABLOKOWANE = 'schedules.transition.blocked',
}

export enum ScheduleAllVerificationMethodsDescriptionEnum {
  KARTA = 'schedules.verificationMethod.card',
  KARTA_PIN = 'schedules.verificationMethod.cardWithPin',
  WEJSCIE = 'schedules.inputOutput.input',
  WYJSCIE = 'schedules.inputOutput.output',
  ODBLOKOWANE = 'schedules.transition.unlocked',
  WARUNKOWO_ODBLOKOWANE = 'schedules.transition.conditionallyUnlocked',
  ZABLOKOWANE = 'schedules.transition.blocked',
}

export enum ScheduleHistoryTypeFilterEnum {
  METODY_WERYFIKACJI = 'METODY_WERYFIKACJI',
  PRZEJSCIE = 'PRZEJSCIE',
  WEJSCIE_WYJSCIE = 'WEJSCIE_WYJSCIE',
  WYJATEK = 'WYJATEK',
}

export enum ScheduleHistoryTypeFilterDescriptionEnum {
  METODY_WERYFIKACJI = 'schedules.filters.type.verfication',
  PRZEJSCIE = 'schedules.filters.type.enter',
  WEJSCIE_WYJSCIE = 'schedules.filters.type.enterExit',
  WYJATEK = 'schedules.filters.type.exception',
}
