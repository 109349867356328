import { Controller, useFormContext } from 'react-hook-form';
import { Modal, Button, Select } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Input } from 'ui/input/Input';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';
import { Form } from 'ui/form/Form';
import { useRenderMultiAutocompleteOption } from 'ui/multiAutocomplete/hooks/useRenderMultiAutocompleteOption';
import { MultiAutocomplete } from 'ui/multiAutocomplete/MultiAutocomplete';
import { ScheduleTypeEnum } from 'api/schedules/schedules.enum';

import { AddScheduleForm, AddScheduleProps } from './AddSchedule.types';
import * as Styled from './AddSchedule.styles';

export const AddSchedule = ({
  servers,
  isModalOpen,
  onModalClose,
  onModalOpen,
  onSubmit,
  isLoadingAddSchedule,
  terminals,
  isLoadingTerminals,
}: AddScheduleProps) => {
  const { formatMessage } = useLocale();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
    getFieldState,
    watch,
  } = useFormContext<AddScheduleForm>();
  const tkdErrorMessage = getFieldState('tkds').error?.message;
  const { renderMultiAutocompleteOption } = useRenderMultiAutocompleteOption();
  const { serverOptions, scheduleModesOptions } = useAppSelectOptions({ servers });

  const isTypeSelected = !!watch('typ');
  const terminalsOptions = terminals.map((terminal) => ({
    label: terminal.nazwa,
    value: terminal.id,
    number: terminal.numerLogicznyKontrolera,
  }));

  return (
    <>
      <Button variant="contained" color="primary" onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['schedules.add'] })}
      </Button>

      <Modal
        header={formatMessage({ id: AppMessages['schedule.add.title'] })}
        onClose={onModalClose}
        open={isModalOpen}
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={!isValid}
        isLoading={isLoadingAddSchedule}
      >
        <Styled.Grid>
          <Styled.NameInputWrapper>
            <Controller
              name="nazwa"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  label={formatMessage({ id: AppMessages['schedule.add.name.label'] })}
                  placeholder={formatMessage({ id: AppMessages['schedule.add.name.placeholder'] })}
                  errorMessage={error?.message}
                  required
                />
              )}
            />
          </Styled.NameInputWrapper>
          <Controller
            name="typ"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Select
                {...field}
                label={formatMessage({ id: AppMessages['schedule.add.type.label'] })}
                placeholder={formatMessage({ id: AppMessages['schedule.add.type.placeholder'] })}
                options={scheduleModesOptions}
                required
                errorMessage={error?.message}
                withHelperText
                onChange={(e) => {
                  setValue('typ', e.target.value as ScheduleTypeEnum, { shouldDirty: true });
                  setValue('tkds', []);
                }}
              />
            )}
          />
          <Form.ControlledSelect
            name="serwerId"
            disabled={!isTypeSelected}
            control={control}
            onChange={(e) => {
              setValue('serwerId', e.target.value as string, { shouldDirty: true });
              setValue('tkds', []);
            }}
            label={formatMessage({ id: AppMessages['schedule.add.server.label'] })}
            withHelperText
            required
            options={serverOptions}
          />
          <div>
            <Controller
              name="tkds"
              control={control}
              render={({ field }) => (
                <MultiAutocomplete
                  {...field}
                  required
                  withLimitedTags
                  stopRotatePopupIndicator
                  disabled={!isTypeSelected}
                  inputSize="big"
                  limitTags={1}
                  onChange={(_, option) => (option ? field.onChange(option) : field.onChange(null))}
                  options={terminalsOptions}
                  loading={isLoadingTerminals}
                  label={formatMessage({ id: AppMessages['schedule.add.reader.label'] })}
                  placeholder={formatMessage({ id: AppMessages['common.list.placeholder'] })}
                  renderOption={renderMultiAutocompleteOption}
                />
              )}
            />

            <Styled.ErrorMessage>{tkdErrorMessage}</Styled.ErrorMessage>
          </div>
        </Styled.Grid>
      </Modal>
    </>
  );
};
