import styled, { css } from 'styled-components';

import { TIME_RANGE_BORDER_COLOR } from '../dailySchedule/timeRange/TimeRange.styles';

export const SCHEDULE_LEGEND_HEIGHT = 56;

export const LegendWrapper = styled.div`
  display: flex;
  gap: 40px;
  padding: 24px 14px 14px;
  height: ${SCHEDULE_LEGEND_HEIGHT}px;
`;

export const TypeWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const TypeColor = styled.span<{ $color?: string }>(
  ({ $color, theme: { palette } }) => css`
    width: 16px;
    height: 16px;
    background-color: ${!$color ? palette.common.white : $color};
    border: 1px solid ${!$color ? TIME_RANGE_BORDER_COLOR : 'transparent'};
  `,
);
