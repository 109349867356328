import { Autocomplete } from 'shared-ui';

import { CardHistoryActionDescriptionEnum } from 'api/cards/cards.enum';
import { GroupPermissionsHistoryActionDescriptionEnum } from 'api/groups/groups.enum';
import { OwnerHistoryActionDescriptionEnum } from 'api/owners/owners.enum';
import { HistoryActionEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import * as Styled from './HistoryActionAutocomplete.styles';
import { HistoryActionAutocompleteProps } from './HistoryActionAutocomplete.types';

const BASE_ACTIONS = [
  HistoryActionEnum.ZABLOKOWANO,
  HistoryActionEnum.ODBLOKOWANO,
  HistoryActionEnum.ZWROCONO,
  HistoryActionEnum.WYDANO,
  HistoryActionEnum.ZARCHIWIZOWANO,
  HistoryActionEnum.PRZYWROCONO,
  HistoryActionEnum.DODANO,
  HistoryActionEnum.ZEDYTOWANO,
] as const;

export const HistoryActionAutocomplete = ({
  selectedAction,
  onChangeAction,
  historyType,
}: HistoryActionAutocompleteProps) => {
  const { formatMessage } = useLocale();

  const currentConfig = {
    owners: BASE_ACTIONS.map((value) => ({
      value,
      label: formatMessage({ id: OwnerHistoryActionDescriptionEnum[value] }),
    })),
    cards: BASE_ACTIONS.map((value) => ({
      value,
      label: formatMessage({ id: CardHistoryActionDescriptionEnum[value] }),
    })),
    groups: (
      [
        HistoryActionEnum.ZARCHIWIZOWANO,
        HistoryActionEnum.PRZYWROCONO,
        HistoryActionEnum.DODANO,
        HistoryActionEnum.ZEDYTOWANO,
      ] as const
    ).map((value) => ({ value, label: formatMessage({ id: CardHistoryActionDescriptionEnum[value] }) })),

    groupPermissions: (
      [HistoryActionEnum.DODANO, HistoryActionEnum.ZEDYTOWANO, HistoryActionEnum.USUNIETO] as const
    ).map((value) => ({ value, label: formatMessage({ id: GroupPermissionsHistoryActionDescriptionEnum[value] }) })),

    ownersEffectivePermissions: (
      [HistoryActionEnum.DODANO, HistoryActionEnum.ZEDYTOWANO, HistoryActionEnum.USUNIETO] as const
    ).map((value) => ({ value, label: formatMessage({ id: GroupPermissionsHistoryActionDescriptionEnum[value] }) })),

    control: ([HistoryActionEnum.DODANO, HistoryActionEnum.ZEDYTOWANO, HistoryActionEnum.USUNIETO] as const).map(
      (value) => ({ value, label: formatMessage({ id: GroupPermissionsHistoryActionDescriptionEnum[value] }) }),
    ),

    wanted: ([HistoryActionEnum.DODANO, HistoryActionEnum.ZEDYTOWANO, HistoryActionEnum.USUNIETO] as const).map(
      (value) => ({ value, label: formatMessage({ id: GroupPermissionsHistoryActionDescriptionEnum[value] }) }),
    ),

    announcements: ([HistoryActionEnum.DODANO, HistoryActionEnum.ZEDYTOWANO, HistoryActionEnum.USUNIETO] as const).map(
      (value) => ({ value, label: formatMessage({ id: GroupPermissionsHistoryActionDescriptionEnum[value] }) }),
    ),

    schedules: ([HistoryActionEnum.DODANO, HistoryActionEnum.ZEDYTOWANO, HistoryActionEnum.USUNIETO] as const).map(
      (value) => ({ value, label: formatMessage({ id: GroupPermissionsHistoryActionDescriptionEnum[value] }) }),
    ),
  } as const;

  const options = currentConfig[historyType];

  return (
    <Autocomplete
      value={
        selectedAction
          ? options
              .map((option) => ({ ...option, label: option.label.toUpperCase() }))
              .find((option) => option.value === selectedAction)
          : null
      }
      onChange={(_e, option) => onChangeAction(option?.value as HistoryActionEnum)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      options={options}
      renderOption={(props, option) =>
        option.value ? (
          <li {...props} key={option.value}>
            <Styled.HistoryActionOption status={option.value as HistoryActionEnum} />
          </li>
        ) : null
      }
      placeholder={formatMessage({ id: AppMessages['cards.history.filters.action'] })}
      inputVariant="outlined"
      inputSize="big"
    />
  );
};
