import { useEffect, useState } from 'react';
import { useQueryClient } from 'shared-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { fetchAvailableTerminalsForSchedule } from 'api/terminals/terminals';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useMutation } from 'hooks/useMutation/useMutation';
import { GroupTerminalsPermissionsIdSortEnum } from 'context/query/queryContext/QueryContext.enum';
import { useModalState } from 'hooks/useModalState/useModalState';
import { fetchServers } from 'api/servers/servers';
import { addScheduleTerminals, fetchScheduleDetails } from 'api/schedules/schedules';
import { ScheduleTypeEnum } from 'api/schedules/schedules.enum';

import { useAddScheduleTerminalsValidation } from './AddScheduleTerminals.validation';
import { AddScheduleTerminalsFormBody, AddScheduleTerminalsContainerProps } from './AddScheduleTerminals.types';
import { AddScheduleTerminals } from './AddScheduleTerminals';

export const AddScheduleTerminalsContainer = ({ scheduleId }: AddScheduleTerminalsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const { schema } = useAddScheduleTerminalsValidation();
  const [selectedServerId, setSelectedServerId] = useState('');

  const methods = useForm<AddScheduleTerminalsFormBody>({
    defaultValues: {
      idTkds: [],
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { data: scheduleDetailsResponse, isLoading: isLoadingScheduleDetails } = useQuery(
    [QueryKeyEnum.SCHEDULE_DETAILS],
    () => fetchScheduleDetails(scheduleId),
  );
  const typHarmonogramu = scheduleDetailsResponse?.typ;

  const { data: serversResponse, isLoading: isLoadingServers } = useQuery([QueryKeyEnum.SERVERS], fetchServers, {
    onSuccess: (serversResponse) => {
      if (serversResponse.data.length) {
        setSelectedServerId(serversResponse.data[0].id);
      }
    },
  });

  const { data: terminalsResponse, isInitialLoading: isLoadingTerminals } = useQuery(
    [QueryKeyEnum.TERMINALS, selectedServerId, typHarmonogramu],
    () => fetchAvailableTerminalsForSchedule(selectedServerId),
    {
      args: {
        sort: [GroupTerminalsPermissionsIdSortEnum.ASC],
        zaakceptowane: true,
        typHarmonogramu,
        ...(typHarmonogramu === ScheduleTypeEnum.WEJSCIE_WYJSCIE ? { typTkd: 'AS' } : {}),
      },
      enabled: isModalOpen && !!selectedServerId && !!typHarmonogramu,
      onSuccess: (response) => {
        if (!response.data.length) {
          return methods.setError('idTkds', {
            message: formatMessage({ id: AppMessages['schedule.add.validation.reader.defined'] }),
          });
        }
        methods.clearErrors('idTkds');
      },
    },
  );

  const { mutate: onAddScheduleTerminals, isLoading } = useMutation(addScheduleTerminals, {
    onSuccess: (_, { body }) => {
      queryClient.invalidateQueries([QueryKeyEnum.SCHEDULE_TERMINALS]);
      queryClient.invalidateQueries([QueryKeyEnum.TERMINALS, selectedServerId]);

      toast.dark(
        formatMessage({
          id: AppMessages[
            body.length === 1
              ? 'schedule.terminals.add.terminal.success'
              : 'schedule.terminals.add.terminal.success.multi'
          ],
        }),
      );

      onModalClose();
    },
  });

  const onSubmit = (body: AddScheduleTerminalsFormBody) => {
    onAddScheduleTerminals({
      id: scheduleId,
      body: body.idTkds.map(({ value }) => `${value}`),
    });
  };

  useEffect(() => {
    methods.reset();
  }, [isModalOpen, selectedServerId]);

  return (
    <FormProvider {...methods}>
      <AddScheduleTerminals
        onSubmit={onSubmit}
        isModalOpen={isModalOpen}
        onModalOpen={onModalOpen}
        onModalClose={onModalClose}
        isLoading={isLoading || isLoadingScheduleDetails}
        isLoadingTerminals={isLoadingTerminals}
        terminals={terminalsResponse?.data || []}
        selectedServerId={selectedServerId}
        servers={serversResponse?.data || []}
        setSelectedServerId={setSelectedServerId}
        isLoadingServers={isLoadingServers}
      />
    </FormProvider>
  );
};
