import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { APIError, ClientError, useErrorHandler, useQueryClient } from 'shared-ui';

import { useModalState } from 'hooks/useModalState/useModalState';
import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyEnum } from 'core/global.enum';
import { fetchServers } from 'api/servers/servers';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { addException } from 'api/schedules/schedules';
import { AddExceptionBody } from 'api/schedules/schedules.types';
import { fetchAvailableTerminalsForException } from 'api/terminals/terminals';
import { GroupTerminalsPermissionsIdSortEnum } from 'context/query/queryContext/QueryContext.enum';

import { AddException } from './AddException';
import { AddExceptionForm } from './AddException.types';
import { useAddExceptionValidation } from './AddException.validation';

export const AddExceptionContainer = () => {
  const { isModalOpen, onModalClose, onModalOpen } = useModalState();
  const { schema } = useAddExceptionValidation();
  const { formatMessage } = useLocale();
  const queryClient = useQueryClient();
  const { handleError } = useErrorHandler();

  const formMethods = useForm<AddExceptionForm>({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { data: serversResponse } = useQuery([QueryKeyEnum.SERVERS], fetchServers);
  const servers = serversResponse?.data || [];
  const serwerId = formMethods.watch('serwerId');
  const exceptionDate = formMethods.watch('data');

  const { data: terminalsResponse, isInitialLoading: isLoadingTerminals } = useQuery(
    [QueryKeyEnum.TERMINALS, serwerId, exceptionDate],
    () => fetchAvailableTerminalsForException(serwerId),
    {
      args: {
        sort: [GroupTerminalsPermissionsIdSortEnum.ASC],
        zaakceptowane: true,
        wyswietlacz: true,
        exceptionDate: exceptionDate
          ? new Date(
              exceptionDate.getFullYear(),
              exceptionDate.getMonth(),
              exceptionDate.getDate(),
              0,
              0,
              0,
            ).toISOString()
          : undefined,
      },
      enabled: !!serwerId && !!exceptionDate,
      onSuccess: (response) => {
        if (!response.data.length) {
          return formMethods.setError('tkds', {
            message: formatMessage({ id: AppMessages['schedules.exceptions.add.validation.reader.defined'] }),
          });
        }
        formMethods.clearErrors('tkds');
      },
    },
  );
  const terminals = terminalsResponse?.data || [];

  const { mutate: onAddException, isLoading: isLoadingAddException } = useMutation(addException, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.SCHEDULES_EXCEPTIONS, serwerId]);
      toast.dark(formatMessage({ id: AppMessages['schedules.exceptions.add.successMessage'] }));
      onModalClose();
    },
    onError: (error: ClientError<APIError>) => {
      handleError({ error, omitAPIErrorMessage: true });
    },
  });

  const onSubmit = ({ data, tkds, typ }: AddExceptionForm) => {
    if (!data) return;

    onAddException({
      data: new Date(data.getFullYear(), data.getMonth(), data.getDate(), 0, 0, 0).toISOString(),
      tkds: tkds?.map((option) => option.value),
      typ,
    } as unknown as AddExceptionBody);
  };

  useEffect(() => {
    servers.length && formMethods.reset({ serwerId: servers[0].id });
  }, [servers]);

  useEffect(() => {
    formMethods.reset();
  }, [isModalOpen]);

  return (
    <FormProvider {...formMethods}>
      <AddException
        servers={servers}
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
        onSubmit={onSubmit}
        isLoadingAddException={isLoadingAddException}
        isLoadingTerminals={isLoadingTerminals}
        terminals={terminals}
      />
    </FormProvider>
  );
};
