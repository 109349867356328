import { useEffect } from 'react';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import { AnnouncementsQueryKeysEnum, QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { fetchAnnouncements } from 'api/announcements/announcements';
import { useExpandedServerId } from 'hooks/useExpandedServerId/useExpandedServerId';

import { AnnouncementsTable } from './AnnouncementsTable';
import { AnnouncementsTableContainerProps } from './AnnouncementsTable.types';

export const AnnouncementsTableContainer = ({
  expandedServer,
  setAnnouncementsCount,
  checkedAnnouncements,
  setCheckedAnnouncements,
  announcementsTableSearchQuery,
}: AnnouncementsTableContainerProps) => {
  const serverId = expandedServer?.id || '';
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.ANNOUNCEMENTS]: announcementsQuery },
  } = useLocaleStoreQuery();
  const { setExpandedServerId } = useExpandedServerId();

  const { data: announcementsResponse } = useQuery(
    [QueryKeyEnum.ANNOUNCEMENTS, serverId, announcementsQuery, announcementsTableSearchQuery],
    () => fetchAnnouncements(serverId),
    {
      args: { ...announcementsQuery, [AnnouncementsQueryKeysEnum.QUERY]: announcementsTableSearchQuery },
      enabled: !!expandedServer,
      onSuccess: (response) => {
        setAnnouncementsCount(response.count);
      },
    },
  );

  useEffect(() => {
    setExpandedServerId(serverId);
  }, [serverId]);

  return (
    <AnnouncementsTable
      checkedAnnouncements={checkedAnnouncements}
      announcementsResponse={announcementsResponse}
      setCheckedAnnouncements={setCheckedAnnouncements}
      announcementsTableSearchQuery={announcementsTableSearchQuery}
    />
  );
};
