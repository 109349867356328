import { SchedulePeriod } from 'api/schedules/schedules.types';

export const useDefaultSchedulePeriod = () => {
  const defaultSchedulePeriod: SchedulePeriod = {
    id: `${Math.random()}`,
    startTime: null,
    endTime: null,
    typ: undefined,
    updatedAt: new Date(),
  };

  return { defaultSchedulePeriod };
};
