import { format } from 'date-fns';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { HistoryList } from 'ui/historyList/HistoryList';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { ScheduleTransitionDescriptionEnum, ScheduleTypeDescriptionEnum } from 'api/schedules/schedules.enum';

import { SchedulesHistoryFilters } from './schedulesHistoryFilters/SchedulesHistoryFilters';
import { SchedulesHistoryProps } from './SchedulesHistory.types';
import { PeriodsHistoryItem } from './periodsHistoryItem/PeriodsHistoryItem';

export const SchedulesHistory = ({
  schedulesHistoryResponse,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  servers,
  executorSearchQuery,
  setExecutorSearchQuery,
  tkdSearchQuery,
  setTkdSearchQuery,
  scheduleSearchQuery,
  setScheduleSearchQuery,
  onClearControlHistoryQuery,
}: SchedulesHistoryProps) => {
  const { formatMessage } = useLocale();

  const {
    storedQueries: { schedulesHistory: schedulesHistoryQuery },
  } = useLocaleStoreQuery();

  const isClearFiltersButtonVisible =
    Object.values(schedulesHistoryQuery).some((query) => query) ||
    !!executorSearchQuery ||
    !!tkdSearchQuery ||
    !!scheduleSearchQuery;

  return (
    <HistoryList
      data={schedulesHistoryResponse?.data || []}
      isLoading={isLoading}
      filters={{
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        isClearButtonVisible: isClearFiltersButtonVisible,
        onClearFilters: onClearControlHistoryQuery,
        render: () => (
          <SchedulesHistoryFilters
            servers={servers}
            tkdSearchQuery={tkdSearchQuery}
            executorSearchQuery={executorSearchQuery}
            scheduleSearchQuery={scheduleSearchQuery}
            setExecutorSearchQuery={setExecutorSearchQuery}
            setScheduleSearchQuery={setScheduleSearchQuery}
            setTkdSearchQuery={setTkdSearchQuery}
          />
        ),
      }}
      pagination={{
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        count: schedulesHistoryResponse?.count || 0,
      }}
      renderHistoryItemBody={(history) => (
        <>
          {history.wykonawca && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['schedules.history.label.executor'] })}
              value={`${history.wykonawca.imie} ${history.wykonawca.nazwisko}`}
            />
          )}

          {history.dataWyjatku && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['schedules.history.label.exceptionDate'] })}
              value={format(new Date(history.dataWyjatku), 'yyyy / MM / dd')}
            />
          )}

          {history.nazwa && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['schedules.history.label.name'] })}
              value={history.nazwa}
            />
          )}

          {history.typ && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['schedules.history.label.type'] })}
              value={formatMessage({ id: ScheduleTypeDescriptionEnum[history.typ] })}
            />
          )}

          {(history.tkds || history.tkdsAdded || history.tkdsDeleted) && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['schedules.history.label.server'] })}
              value={
                history.tkds
                  ? history.tkds[0].server.nazwa
                  : history.tkdsAdded
                  ? history.tkdsAdded[0].server.nazwa
                  : history.tkdsDeleted
                  ? history.tkdsDeleted[0].server.nazwa
                  : ''
              }
            />
          )}

          {history.tkds && (
            <HistoryList.ActionListdBodyItem
              title={formatMessage(
                { id: AppMessages['schedules.history.label.readers'] },
                { count: history.tkds.length },
              )}
              items={history.tkds}
              shouldScrollToTop={!!schedulesHistoryQuery.tkd && !isLoading}
              renderItemBody={(tkd) => (
                <HistoryList.RenderedActionListdBodyItem
                  numberPrefix={formatMessage({ id: AppMessages['common.id'] })}
                  number={tkd.numer}
                  value={tkd.nazwa}
                />
              )}
            />
          )}

          {history.tkdsAdded && (
            <HistoryList.ActionListdBodyItem
              title={formatMessage(
                { id: AppMessages['schedules.history.label.addedReaders'] },
                { count: history.tkdsAdded.length },
              )}
              items={history.tkdsAdded}
              shouldScrollToTop={!!schedulesHistoryQuery.tkd && !isLoading}
              renderItemBody={(tkd) => (
                <HistoryList.RenderedActionListdBodyItem
                  numberPrefix={formatMessage({ id: AppMessages['common.id'] })}
                  number={tkd.numer}
                  value={tkd.nazwa}
                />
              )}
            />
          )}

          {history.tkdsDeleted && (
            <HistoryList.ActionListdBodyItem
              title={formatMessage(
                { id: AppMessages['schedules.history.label.removedReaders'] },
                { count: history.tkdsDeleted.length },
              )}
              items={history.tkdsDeleted}
              shouldScrollToTop={!!schedulesHistoryQuery.tkd && !isLoading}
              renderItemBody={(tkd) => (
                <HistoryList.RenderedActionListdBodyItem
                  numberPrefix={formatMessage({ id: AppMessages['common.id'] })}
                  number={tkd.numer}
                  value={tkd.nazwa}
                />
              )}
            />
          )}

          {history.przejscie && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['schedules.history.label.entry'] })}
              value={formatMessage({ id: ScheduleTransitionDescriptionEnum[history.przejscie] })}
            />
          )}

          {history.dodaneOkresy && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['schedules.history.label.addedPeriods'] })}
              value={<PeriodsHistoryItem period={history.dodaneOkresy} scheduleType={history.typ} />}
            />
          )}

          {history.usunieteOkresy && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['schedules.history.label.removedPeriods'] })}
              value={<PeriodsHistoryItem period={history.usunieteOkresy} scheduleType={history.typ} />}
            />
          )}

          {history.powod && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['schedules.history.label.reason'] })}
              value={history.powod}
            />
          )}
        </>
      )}
    />
  );
};
