import * as Styled from './ScheduleTerminalsMultiActions.styles';
import { ScheduleTerminalsMultiActionsProps } from './ScheduleTerminalsMultiActions.types';
import { DeleteScheduleTerminalsContainer } from './deleteScheduleTerminals/DeleteScheduleTerminalsContainer';

export const ScheduleTerminalsMultiActions = ({
  checkedTerminals,
  setCheckedTerminals,
  scheduleId,
  terminalsCount,
}: ScheduleTerminalsMultiActionsProps) => {
  return (
    <Styled.Container>
      <DeleteScheduleTerminalsContainer
        checkedTerminals={checkedTerminals}
        setCheckedTerminals={setCheckedTerminals}
        scheduleId={scheduleId}
        terminalsCount={terminalsCount}
      />
    </Styled.Container>
  );
};
