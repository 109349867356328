import styled, { css } from 'styled-components';
import { Tooltip as MaterialTooltip } from '@mui/material';

const TIME_LINE_HEIGHT = 31;
const FULL_HOUR_HEIGHT = 20;
const TIME_RANGE_BORDER_WIDTH = 1;
export const TIME_RANGE_BORDER_COLOR = '#A4AAC4';

export const TimeRangeWrapper = styled.div<{ numberOfSlots: number }>(
  ({ numberOfSlots }) => css`
    position: relative;
    display: grid;
    grid-template-columns: repeat(${numberOfSlots}, 1fr) 1px;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: ${TIME_RANGE_BORDER_WIDTH}px solid ${TIME_RANGE_BORDER_COLOR};
    border-radius: 0 0 4px 4px;
  `,
);

export const HourWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 11px;
`;

export const TimelineWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Timeline = styled.div<{ isFullHour: boolean; isFirstSlot: boolean; isLastSlot: boolean }>(
  ({ isFullHour, isFirstSlot, isLastSlot }) => css`
    height: ${isFirstSlot || isLastSlot ? TIME_LINE_HEIGHT - 2 : TIME_LINE_HEIGHT}px;
    position: relative;
    top: ${isFirstSlot || isLastSlot ? '-2px' : undefined};
    border-right: ${isLastSlot ? undefined : `1px solid ${TIME_RANGE_BORDER_COLOR}`};
    border-left: ${isFirstSlot ? `1px solid ${TIME_RANGE_BORDER_COLOR}` : undefined};

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 5px;
      height: 5px;
      background-color: ${isFullHour ? TIME_RANGE_BORDER_COLOR : 'transparent'};
      border-radius: 100%;
      transform: translate(-50%, -50%);
      z-index: 4;
    }
  `,
);

export const TopBorder = styled.div<{ isFirstSlot: boolean }>(
  ({ isFirstSlot }) => css`
    position: absolute;
    top: ${isFirstSlot ? -2 : 0}px;
    left: 0;
    right: 0;
    border-top: ${TIME_RANGE_BORDER_WIDTH}px solid ${TIME_RANGE_BORDER_COLOR};
    z-index: 5;
  `,
);

export const Overlay = styled.div<{
  $startIndex: number;
  $endIndex: number;
  $numberOfSlots: number;
  $backgroundColor: string;
}>(
  ({ $startIndex, $endIndex, $numberOfSlots, $backgroundColor }) => css`
    position: absolute;
    left: calc(${($startIndex / $numberOfSlots) * 100}% - ${TIME_RANGE_BORDER_WIDTH}px);
    width: calc(${(($endIndex - $startIndex) / $numberOfSlots) * 100}% + ${TIME_RANGE_BORDER_WIDTH}px);
    height: ${TIME_LINE_HEIGHT}px;
    background-color: #64e6f6;
    border-radius: ${$startIndex === 0 ? '0 0 0 4px' : undefined};
    border-radius: ${$endIndex === $numberOfSlots ? '0 0 4px 0' : undefined};
    z-index: 2;
    background-color: ${$backgroundColor};
    border-left: ${TIME_RANGE_BORDER_WIDTH}px solid ${TIME_RANGE_BORDER_COLOR};
    border-right: ${TIME_RANGE_BORDER_WIDTH}px solid ${TIME_RANGE_BORDER_COLOR};
  `,
);

export const FullTimeNumber = styled.div<{ $isLastSlot: boolean }>(
  ({ $isLastSlot }) => css`
    height: ${FULL_HOUR_HEIGHT}px;
    margin-left: ${!$isLastSlot ? '-14px' : '-4px'};
  `,
);

export const Tooltip = styled(({ className, ...props }) => (
  <MaterialTooltip {...props} classes={{ popper: className, tooltip: 'tooltip' }} />
))(
  ({ theme }) => css`
    & .tooltip {
      margin: 8px 0 0 0;
      padding: 14px 16px;
      font-size: 12px;
      color: ${theme.palette.common.white};
      background-color: ${theme.palette.background.paper};
    }
  `,
);
