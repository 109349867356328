import { useParams } from 'react-router';
import { useQuery } from 'shared-ui';

import { QueryKeyEnum } from 'core/global.enum';
import { fetchScheduleDetailsWithPeriods } from 'api/schedules/schedules';
import { ScheduleDetailsWithPeriods } from 'api/schedules/schedules.types';
import { transformDays } from '../utils/transformDays';
import { ScheduleTypeEnum } from 'api/schedules/schedules.enum';

import { ScheduleDetails } from './ScheduleDetails';
import { DaysOfWeekEnum } from './ScheduleDetails.enum';

export const ScheduleDetailsContainer = () => {
  const { id } = useParams<{ id: string }>();

  const { data, isLoading } = useQuery([QueryKeyEnum.SCHEDULE_DETAILS], () => fetchScheduleDetailsWithPeriods(id));

  const defaultScheduleDetails: ScheduleDetailsWithPeriods = {
    id: Math.random().toString(),
    typ: ScheduleTypeEnum.METODY_WERYFIKACJI,
    nazwa: '',
    dni: {
      [DaysOfWeekEnum.MONDAY]: [],
      [DaysOfWeekEnum.TUESDAY]: [],
      [DaysOfWeekEnum.WEDNESDAY]: [],
      [DaysOfWeekEnum.THURSDAY]: [],
      [DaysOfWeekEnum.FRIDAY]: [],
      [DaysOfWeekEnum.SATURDAY]: [],
      [DaysOfWeekEnum.SUNDAY]: [],
    },
  };

  const scheduleDetails: ScheduleDetailsWithPeriods = data
    ? { ...data, dni: data.dni ? transformDays(data.dni) : defaultScheduleDetails.dni }
    : defaultScheduleDetails;

  return <ScheduleDetails scheduleDetails={scheduleDetails} isLoading={isLoading} />;
};
