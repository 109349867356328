import { Typography } from '@mui/material';
import { useLocale } from 'shared-ui';

import { DaysSelector } from 'ui/daysSelector/DaysSelector';
import { AppMessages } from 'i18n/messages';
import {
  ScheduleAllVerificationMethodsDescriptionEnum,
  ScheduleTypeSingularDescriptionEnum,
} from 'api/schedules/schedules.enum';

import { PeriodsHistoryItemProps } from './PeriodsHistoryItem.types';
import * as Styled from './PeriodsHistoryItem.styles';

export const PeriodsHistoryItem = ({ period, scheduleType }: PeriodsHistoryItemProps) => {
  const { formatMessage } = useLocale();

  return (
    <Styled.PeriodsWrapper>
      <Styled.TimeWrapper>
        <Styled.PeriodField $small>{period.startTime}</Styled.PeriodField>-
        <Styled.PeriodField $small>{period.endTime}</Styled.PeriodField>
        <Typography>
          {scheduleType
            ? formatMessage({ id: ScheduleTypeSingularDescriptionEnum[scheduleType] })
            : formatMessage({ id: AppMessages['schedules.history.label.veryficationMethod'] })}
        </Typography>
        <Styled.PeriodField>
          {formatMessage({
            id: ScheduleAllVerificationMethodsDescriptionEnum[period.typ],
          })}
        </Styled.PeriodField>
      </Styled.TimeWrapper>

      <DaysSelector selectedDays={period.dni} onChange={() => {}} disabled={() => true} />
    </Styled.PeriodsWrapper>
  );
};
