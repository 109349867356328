import {
  QueryKeyLocalStorageEnum,
  WantedTerminalsIdSortEnum,
  WantedTerminalsNameSortEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';

export const ExceptionTerminalsTableHeader = () => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.SCHEDULE_EXCEPTION_TERMINALS}
        sortBy={WantedTerminalsIdSortEnum}
        queryHeader={formatMessage({ id: AppMessages['schedules.exceptions.terminals.header.id'] })}
      />

      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.SCHEDULE_EXCEPTION_TERMINALS}
        sortBy={WantedTerminalsNameSortEnum}
        queryHeader={formatMessage({ id: AppMessages['schedules.exceptions.terminals.header.name'] })}
      />
    </>
  );
};
