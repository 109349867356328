import { SchedulePeriod } from 'api/schedules/schedules.types';
import { DaysOfWeekEnum } from '../scheduleDetails/ScheduleDetails.enum';
import { generateTimeSlots } from '../scheduleDetails/dailySchedule/timeRange/utils/generateTimeSlots';

const allSlots = generateTimeSlots(15);

const getCoveredSlots = (periods: SchedulePeriod[]): string[] => {
  const coveredSlots: string[] = [];

  periods.forEach((period) => {
    const startTime = period.startTime || '00:00';
    const endTime = period.endTime === '00:00' ? '24:00' : period.endTime || '00:00';

    const startTimeIndex = allSlots.indexOf(startTime);
    const endTimeIndex = allSlots.indexOf(endTime);

    const coveredRange = allSlots.slice(startTimeIndex, endTimeIndex + 1);

    coveredRange.forEach((slot) => {
      if (!coveredSlots.includes(slot)) {
        coveredSlots.push(slot);
      }
    });
  });

  return coveredSlots;
};

const getIsDayFullyDefined = (periods: SchedulePeriod[]): boolean => {
  const coveredSlots = getCoveredSlots(periods);

  return coveredSlots.length === allSlots.length;
};

export const getFullyDefinedDays = (days: Record<DaysOfWeekEnum, SchedulePeriod[]>): DaysOfWeekEnum[] => {
  return Object.keys(days).reduce<DaysOfWeekEnum[]>((acc, day) => {
    if (getIsDayFullyDefined(days[day as DaysOfWeekEnum])) {
      acc.push(day as DaysOfWeekEnum);
    }

    return acc;
  }, []);
};
