import { Controller, useFormContext } from 'react-hook-form';
import { Modal, Button, Select } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { MultiAutocomplete } from 'ui/multiAutocomplete/MultiAutocomplete';
import { useRenderMultiAutocompleteOption } from 'ui/multiAutocomplete/hooks/useRenderMultiAutocompleteOption';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';
import { Form } from 'ui/form/Form';

import { AddScheduleTerminalsFormBody, AddScheduleTerminalsProps } from './AddScheduleTerminals.types';
import * as Styled from './AddScheduleTerminals.styles';

export const AddScheduleTerminals = ({
  onModalOpen,
  onModalClose,
  isLoading,
  onSubmit,
  isModalOpen,
  isLoadingTerminals,
  terminals,
  selectedServerId,
  setSelectedServerId,
  servers,
  isLoadingServers,
}: AddScheduleTerminalsProps) => {
  const { formatMessage } = useLocale();
  const { renderMultiAutocompleteOption } = useRenderMultiAutocompleteOption();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    getFieldState,
  } = useFormContext<AddScheduleTerminalsFormBody>();

  const tkdErrorMessage = getFieldState('idTkds').error?.message;
  const isTkdNotSelected = !watch('idTkds')?.length;

  const getOptions = () => {
    return terminals.map((terminal) => ({
      label: terminal.nazwa,
      value: terminal.id,
      number: terminal.numerLogicznyKontrolera,
    }));
  };

  const { serverOptions } = useAppSelectOptions({ servers });

  return (
    <>
      <Button onClick={onModalOpen} variant="contained">
        {formatMessage({ id: AppMessages['schedule.terminals.add.button'] })}
      </Button>

      <Modal
        header={formatMessage({ id: AppMessages['schedule.terminals.add.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={isTkdNotSelected || isLoading || !!Object.keys(errors).length}
      >
        <Form.Grid>
          <Select
            required
            value={selectedServerId}
            onChange={(e) => setSelectedServerId(e.target.value as string)}
            label={formatMessage({ id: AppMessages['schedule.terminals.add.server.label'] })}
            options={serverOptions}
          />

          <div>
            <Controller
              name="idTkds"
              control={control}
              render={({ field }) => (
                <MultiAutocomplete
                  {...field}
                  required
                  withLimitedTags
                  stopRotatePopupIndicator
                  inputSize="big"
                  limitTags={1}
                  onChange={(_, option) => (option ? field.onChange(option) : field.onChange(null))}
                  options={getOptions()}
                  loading={isLoadingTerminals || isLoadingServers}
                  loadingText={formatMessage({ id: AppMessages['common.loading'] })}
                  label={formatMessage({ id: AppMessages['schedule.terminals.add.terminal.label'] })}
                  noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
                  placeholder={formatMessage({ id: AppMessages['schedule.terminals.add.terminal.placeholder'] })}
                  renderOption={renderMultiAutocompleteOption}
                />
              )}
            />
            <Styled.ErrorMessage>{tkdErrorMessage}</Styled.ErrorMessage>
          </div>
        </Form.Grid>
      </Modal>
    </>
  );
};
