import { Pagination } from 'shared-ui';

import { ExpandableList } from 'ui/expandableList/ExpandableList';
import { findServerToExpand } from 'helpers/findServerToExpand';

import { SchedulesExceptionsTableContainer } from './schedulesExceptionsTable/SchedulesExceptionsTableContainer';
import { SchedulesExceptionsProps } from './SchedulesExceptions.types';

export const SchedulesExceptions = ({
  servers,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  schedulesExceptionsCount,
  setSchedulesExceptionsCount,
}: SchedulesExceptionsProps) => {
  return (
    <>
      <Pagination
        page={page}
        setPage={setPage}
        count={schedulesExceptionsCount}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />

      <ExpandableList
        data={servers || []}
        isLoading={isLoading}
        title={(server) => server.nazwa}
        initiallyExpandedItem={findServerToExpand(servers || [])}
        toggleItemExpansion={(expandedServer) => {
          setPage(0);
          if (!expandedServer) setSchedulesExceptionsCount(0);
        }}
        renderExpandableItemBody={(server, expandedServer) => (
          <SchedulesExceptionsTableContainer
            key={server.id}
            expandedServer={expandedServer}
            setSchedulesCount={setSchedulesExceptionsCount}
          />
        )}
      />
    </>
  );
};
