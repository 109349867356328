import { AddEventExportFormatPageModeEnum } from 'app/definitions/eventsExportFormats/addEventExportFormat/AddEventExportFormat.enum';

import { AppRouteWithParamsType } from './AppRoutes.types';

export enum AppRoute {
  login = '/login',
  logout = '/logout',

  main = '/main',
  devices = '/main/devices',
  cards = '/main/cards',
  cardsArchived = '/main/cards/archived',
  cardHistory = '/main/cards/history',
  owners = '/main/owners',
  ownersArchived = '/main/owners/archived',
  ownerHistory = '/main/owners/history',
  events = '/main/events',
  eventsHistory = '/main/events/history',
  definitions = '/main/definitions',
  positions = '/main/definitions/positions',
  eventsExportFormats = '/main/definitions/events',
  ownersControl = '/main/definitions/owners-control',
  display = '/main/definitions/display',
  logotype = '/main/definitions/logotype',
  groups = '/main/groups',
  groupsArchived = '/main/groups/archived',
  groupHistory = '/main/groups/history',
  glc = '/main/glc',
  glcArchived = '/main/glc/archived',
  glcHistory = '/main/glc/history',
  glcTerminals = '/main/glc/terminals',
  users = '/main/users',
  profile = '/main/profile',
  controlRandom = '/main/control',
  controlInstant = '/main/control/instant',
  controlHistory = '/main/control/history',
  wanted = '/main/wanted',
  wantedHistory = '/main/wanted/history',
  announcements = '/main/announcements',
  announcementsHistory = '/main/announcements/history',
  schedules = '/main/schedules',
  schedulesExceptions = '/main/schedules/exceptions',
  schedulesHistory = '/main/schedules/history',
}

export type AppRouteParams = {
  groupsHistory: { id: string };
  groupsPermissionsHistory: { id: string };
  cardHistory: { id: string };
  ownerHistory: { id: string };
  ownerPermissionsEffective: { id: string };
  ownerPermissionsIndividual: { id: string };
  ownerPermissionsGroup: { id: string };
  eventsExportFormats: { id?: string; mode: AddEventExportFormatPageModeEnum };
  groupReadersPermissions: { id: string };
  groupGlcPermissions: { id: string };
  groupOwners: { id: string };
  glcHistory: { id: string };
  glcTerminals: { id: string };
  ownerPermissionsEffectiveHistory: { id: string };
  controlInstantReaders: { id: string };
  controlRandomReaders: { id: string };
  wantedReaders: { id: string };
  announcementOwners: { id: string };
  scheduleDetails: { id: string };
  scheduleReaders: { id: string };
  exceptionReaders: { id: string };
};

export const AppRouteWithParams: AppRouteWithParamsType<AppRouteParams> = {
  groupsHistory: {
    path: `${AppRoute.groupHistory}/:id`,
    get: ({ id }) => `${AppRoute.groupHistory}/${id}`,
  },
  groupsPermissionsHistory: {
    path: `${AppRoute.groupHistory}/:id/permissions`,
    get: ({ id }) => `${AppRoute.groupHistory}/${id}/permissions`,
  },
  cardHistory: {
    path: `${AppRoute.cardHistory}/:id`,
    get: ({ id }) => `${AppRoute.cardHistory}/${id}`,
  },
  ownerHistory: {
    path: `${AppRoute.ownerHistory}/:id`,
    get: ({ id }) => `${AppRoute.ownerHistory}/${id}`,
  },
  ownerPermissionsEffective: {
    path: `${AppRoute.owners}/:id/permissions/effective`,
    get: ({ id }) => `${AppRoute.owners}/${id}/permissions/effective`,
  },
  ownerPermissionsIndividual: {
    path: `${AppRoute.owners}/:id/permissions/individual`,
    get: ({ id }) => `${AppRoute.owners}/${id}/permissions/individual`,
  },
  ownerPermissionsGroup: {
    path: `${AppRoute.owners}/:id/permissions/group`,
    get: ({ id }) => `${AppRoute.owners}/${id}/permissions/group`,
  },
  eventsExportFormats: {
    path: `${AppRoute.eventsExportFormats}/:mode/:id?`,
    get: ({ id, mode }) => `${AppRoute.eventsExportFormats}/${mode}/${id}`,
  },
  groupReadersPermissions: {
    path: `${AppRoute.groups}/:id/readers-permissions`,
    get: ({ id }) => `${AppRoute.groups}/${id}/readers-permissions`,
  },
  groupGlcPermissions: {
    path: `${AppRoute.groups}/:id/glc-permissions`,
    get: ({ id }) => `${AppRoute.groups}/${id}/glc-permissions`,
  },
  groupOwners: {
    path: `${AppRoute.groups}/:id/owners`,
    get: ({ id }) => `${AppRoute.groups}/${id}/owners`,
  },
  glcHistory: {
    path: `${AppRoute.glcHistory}/:id`,
    get: ({ id }) => `${AppRoute.glcHistory}/${id}`,
  },
  glcTerminals: {
    path: `${AppRoute.glcTerminals}/:id`,
    get: ({ id }) => `${AppRoute.glcTerminals}/${id}`,
  },
  ownerPermissionsEffectiveHistory: {
    path: `${AppRoute.ownerHistory}/:id/permissions/effective`,
    get: ({ id }) => `${AppRoute.ownerHistory}/${id}/permissions/effective`,
  },
  controlInstantReaders: {
    path: `${AppRoute.controlInstant}/:id/readers`,
    get: ({ id }) => `${AppRoute.controlInstant}/${id}/readers`,
  },
  controlRandomReaders: {
    path: `${AppRoute.controlRandom}/:id/readers`,
    get: ({ id }) => `${AppRoute.controlRandom}/${id}/readers`,
  },
  wantedReaders: {
    path: `${AppRoute.wanted}/:id/readers`,
    get: ({ id }) => `${AppRoute.wanted}/${id}/readers`,
  },
  announcementOwners: {
    path: `${AppRoute.announcements}/:id/owners`,
    get: ({ id }) => `${AppRoute.announcements}/${id}/owners`,
  },
  scheduleDetails: {
    path: `${AppRoute.schedules}/:id`,
    get: ({ id }) => `${AppRoute.schedules}/${id}`,
  },
  scheduleReaders: {
    path: `${AppRoute.schedules}/:id/readers`,
    get: ({ id }) => `${AppRoute.schedules}/${id}/readers`,
  },
  exceptionReaders: {
    path: `${AppRoute.schedulesExceptions}/:id/readers`,
    get: ({ id }) => `${AppRoute.schedulesExceptions}/${id}/readers`,
  },
};
